import { DocumentNode } from 'graphql'
import { TypedDocumentNode } from '@graphql-typed-document-node/core'
import { FetchResult, ApolloError, QueryOptions, MutationOptions, SubscriptionOptions, Observable } from '@apollo/client/core'
import { ref, watch, isRef } from 'vue'
import { apolloClient } from '#/client'
import { unRefObject, useEventHook } from './utils'

import type { Ref } from 'vue'
import type { UseEventHookFunction } from './utils'


// This type converts any input into a Ref-compatible version.
type InputWithRefs<T> = {
  [Property in keyof T]: T[Property] | Ref<T[Property]>
}

export type UseQueryReturnType<T, V> = {
  loading: Ref<boolean>
  error: Ref<boolean>
  query: (variables?: V, options?: Partial<QueryOptions<V, T>>) => void
  load: (variables?: V, options?: Partial<QueryOptions<V, T>>) => Promise<FetchResult<T>>
  result: Ref<FetchResult<T> | undefined>
  onResult: (fn: UseEventHookFunction<FetchResult<T>>) => void
  onError: (fn: UseEventHookFunction<ApolloError>) => void
}

export type UseMutationReturnType<T, V> = {
  loading: Ref<boolean>
  error: Ref<boolean>
  mutate: (variables?: V) => void
  load: (variables?: V) => Promise<FetchResult<T>>
  result: Ref<FetchResult<T> | undefined>
  onResult: (fn: UseEventHookFunction<FetchResult<T>>) => void
  onError: (fn: UseEventHookFunction<ApolloError>) => void
}

export type UseSubscriptionReturnType<T, V> = {
  loading: Ref<boolean>
  error: Ref<boolean>
  load: (variables?: V) => void
  result: Ref<Observable<FetchResult<T>> | undefined>
  onResult: (fn: UseEventHookFunction<FetchResult<T>>) => void
  onError: (fn: UseEventHookFunction<ApolloError>) => void
}


/**
 * Immediately runs a query.
 *
 * @param queryArg The query to run
 * @param variables The input variables (if any).
 * @param lazy If true, doesn't run the query immediately.
 *
 * @todo optionally rerun the query when an input ref changes?
 * @todo add refetch method
 *
 * @returns
 */
const useQuery = <T, V extends Record<string, any>> (queryArg: DocumentNode | TypedDocumentNode<T, V>, variables?: InputWithRefs<V>, queryOptions?: Partial<QueryOptions<V, T>>, lazy = false): UseQueryReturnType<T, V> => {
  const loading = ref(false)
  const error = ref(false)

  const results = useEventHook<FetchResult<T>>()
  const errors = useEventHook<ApolloError>()

  const result: Ref<FetchResult<T> | undefined> = ref()

  const input = {} as V

  const vars = variables

  const load = (inlineVars?: InputWithRefs<V>, queryOptions?: Partial<QueryOptions<V, T>>) => {
    const options: QueryOptions<V, T> = Object.assign({}, {
      query: queryArg,
    }, queryOptions)

    if (inlineVars) {
      unRefObject(inlineVars, input)
      options.variables = input
    } else if (vars) {
      unRefObject(vars, input)
      options.variables = input
    }

    return apolloClient.query<T, V>(options)
  }

  const query = async (inlineVars?: InputWithRefs<V>) => {
    loading.value = true

    try {
      error.value = false
      result.value = await load(inlineVars ? inlineVars : vars, queryOptions)
      results.trigger(result.value)
    } catch (e: unknown) {
      error.value = true
      errors.trigger(e as ApolloError)
    } finally {
      loading.value = false
    }
  }

  // Run the query immediately if it's not a lazy query.
  if (!lazy) {
    query()
  }

  return {
    loading,
    error,
    query,
    load,
    result,
    onResult: results.on,
    onError: errors.on,
  }
}


/**
 * Same as useQuery, but won't run the query immediately.
 * @param queryArg The query to run
 * @param variables The input variables (if any).
 * @returns
 */
const useLazyQuery = <T, V extends Record<string, any>> (queryArg: DocumentNode | TypedDocumentNode<T, V>, variables?: InputWithRefs<V>, options?: Partial<QueryOptions<V, T>>) => useQuery(queryArg, variables, options, true)


/**
 * Runs a mutation.
 *
 * @param queryArg The mutation to run.
 * @param variables The input variables (if any).
 * @returns
 */
const useMutation = <T, V extends Record<string, any>> (queryArg: DocumentNode | TypedDocumentNode<T, V>, variables?: InputWithRefs<V>): UseMutationReturnType<T, V> => {
  const loading = ref(false)
  const error = ref(false)

  const results = useEventHook<FetchResult<T>>()
  const errors = useEventHook<ApolloError>()

  const result: Ref<FetchResult<T> | undefined> = ref()

  const input = {} as V

  const load = (variables?: InputWithRefs<V>) => {
    const options: MutationOptions<T, V> = {
      mutation: queryArg,
    }

    if (variables) {
      unRefObject(variables, input)
      options.variables = input
    }

    return apolloClient.mutate<T, V>(options)
  }

  const mutate = async (inlineVars?: InputWithRefs<V>) => {
    loading.value = true

    try {
      error.value = false
      result.value = await load(inlineVars ? inlineVars : variables)
      loading.value = false
      results.trigger(result.value)
    } catch (e: unknown) {
      error.value = true
      errors.trigger(e as ApolloError)
    } finally {
      loading.value = false
    }
  }

  return {
    loading,
    error,
    mutate,
    load,
    result,
    onResult: results.on,
    onError: errors.on,
  }
}


/**
 * Subscribes to data.
 *
 * @todo have a stop/start options that watches for a ref change. Needed for
 * triggering the subscription when websockets are finally available.
 */
type UseSubscriptionOptions = {
  ready?: boolean | Ref<boolean>
}

const useSubscription = <T, V extends Record<string, any>> (queryArg: DocumentNode | TypedDocumentNode<T, V>, variables?: InputWithRefs<V> | null, options?: UseSubscriptionOptions): UseSubscriptionReturnType<T, V> => {
  const loading = ref(false)
  const error = ref(false)
  const started = ref(false)
  const subscription = ref()

  const results = useEventHook<FetchResult<T>>()
  const errors = useEventHook<ApolloError>()

  const result: Ref<Observable<FetchResult<T>> | undefined> = ref()

  const input = {} as V

  const load = () => {
    const options: SubscriptionOptions<V, T> = {
      query: queryArg,
    }

    if (variables) {
      unRefObject(variables, input)
      options.variables = input
    }

    subscription.value = apolloClient.subscribe<T, V>(options)

  }

  // Prepare the subscription.
  load()

  if (options && isRef(options.ready)) {
    watch(
      options.ready,
      ready => {
        if (!started.value && ready) {
          started.value = true
          subscription.value.subscribe({
            next: (res: FetchResult<T>) => {
              results.trigger(res)
            },
            error: (e: FetchResult<T>) => {
              errors.trigger(e as ApolloError)
            },
          })
        }

        if (started.value && !ready) {
          started.value = false
          subscription.value.unsubscribe()
        }
      }, { immediate: true }
    )
  }

  return {
    loading,
    error,
    load,
    result,
    onResult: results.on,
    onError: errors.on,
  }
}


export {
  useQuery,
  useLazyQuery,
  useMutation,
  useSubscription,
}
