<template>
  <Teleport to="#snacks">
    <div class="fixed left-1/2 transform -translate-x-1/2 top-0 z-50 pt-4 px-4 md:px-6 lg:px-8 flex flex-col w-full sm:w-auto md:w-[36rem] lg:w-[40rem]">
      <TransitionGroup
        enter-active-class="transform transition duration-150 ease-out"
        enter-from-class="opacity-0 scale-90"
        enter-to-class="opacity-100 scale-100"
        leave-active-class="transition duration-150 ease-in"
        leave-from-class="opacity-100"
        leave-to-class="opacity-0"
      >
        <div
          v-for="snack in snacks"
          :key="snack.id"
          class="flex gap-2 bg-black text-white shadow-lg overflow-hidden w-full sm:w-[calc(100vw-2rem)] md:w-[36rem] lg:w-[40rem] rounded-[12px] p-4 md:px-5 md:py-4 mb-2"
          data-test-id="snack"
        >
          <div class="basis-7 md:basis-8 grow-0 shrink-0 w-0">
            <span
              class="flex items-center justify-center w-7 h-7 md:w-8 md:h-8 rounded-full"
              :class="[
                bgColor(snack.color),
                snack.actionLabel ? 'md:mt-[3px] lg:mt-[6px]' : 'mt-0',
              ]"
              :data-test-id="snack.color"
            >
              <Icon
                v-if="snack.color === 'positive'"
                class="w-4 h-4 md:w-5 md:h-5"
                icon="CheckIcon"
                :data-test-id="`snackIcon-${snack.color}`"
              />
              <span v-else-if="snack.color === 'negative' || snack.color === 'warning'">!</span>
              <span v-else>i</span>
            </span>
          </div>

          <div class="grow flex flex-col md:flex-row md:items-center gap-2">
            <Body
              data-test-id="snack-message"
              size="sm"
              class="mt-[6px] md:mt-0 grow"
              white-text
            >
              {{ snack.message }}
            </Body>

            <GlobalButton
              v-if="snack.actionLabel"
              priority="secondary-dark"
              size="sm"
              class="self-start"
              data-test-id="actionLabel"
              padding-size="sm"
              @click="snack.action?.($event, snack.id)"
            >
              {{ snack.actionLabel }}
            </GlobalButton>
          </div>

          <span
            class="cursor-pointer text-brand-border hover:text-white transition-colors flex md:items-center justify-center"
            data-test-id="removeSnack"
            @click="removeSnack(snack.id)"
          >
            <Icon
              icon="XIcon"
              class="w-5 h-5"
              data-test-id="xicon"
            />
          </span>
        </div>
      </TransitionGroup>
    </div>
  </Teleport>
</template>

<script setup lang="ts">
import useSnackbar from '#composables/use-snackbar'
import Icon from '#components/icon.vue'
import GlobalButton from '#components/global/global-button'
import Body from '#components/typography/body'

const { snacks, removeSnack } = useSnackbar()

const props = defineProps({
  darkBackground: {
    type: Boolean,
    default: false,
  },
})

const bgColor = (color: string | undefined) => {
  switch (color) {
    case 'positive':
      return [ props.darkBackground ? 'bg-brand-success-dark' : 'bg-brand-success-light']
    case 'negative':
      return ['bg-brand-error']
    case 'warning':
      return ['bg-brand-warning']
    default:
    case 'brand':
      return ['bg-[#60A5FA]']
  }
}

const icon = (color: string) => {
  switch (color) {
    default:
    case 'positive':
      return 'CheckIcon'
  }
}
</script>
