import { ref, computed } from 'vue'

import { useSubscription } from '#composables/use-apollo'
import useSockets from '#composables/use-sockets'
import useLogin from '#composables/use-login'
import { ACCOUNT_ACTIVITY } from '#subscriptions/AccountActivity'
import type {
  AccountActivity,
  AccountActivityVariables,
  AccountActivity_accountActivity,
  AccountActivity_accountActivity_PendingMomentComplete,
  AccountActivity_accountActivity_PendingMomentFailed,
  AccountActivity_accountActivity_PendingMomentProcessing,
  AccountActivity_accountActivity_PendingMomentPending,
  AccountActivity_accountActivity_TokenPurchaseConfirming,
  AccountActivity_accountActivity_TokenPurchaseFailed,
  AccountActivity_accountActivity_TokenPurchasePending,
  AccountActivity_accountActivity_TokenPurchaseSuccess,
} from '#graphql/types/AccountActivity'

const { isReady } = useSockets()
const { accountId } = useLogin()

const id = computed(() => accountId.value)
const accountActivityOnResult = ref<any | null>(null)

export const useAccountActivity = () => {
  const {
    onResult,
    // restart,
    // stop,
  } = useSubscription<AccountActivity, AccountActivityVariables>(ACCOUNT_ACTIVITY, { id: id.value }, {
    ready: isReady,
  })

  onResult((res) => {
    accountActivityOnResult.value = res.data?.accountActivity
  })

  return {
    accountActivityOnResult,
  }
}
