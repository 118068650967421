<!-- eslint-disable vue/attribute-hyphenation -->
<template>
  <Modal
    :is-open="isOpen"
    :hide-action-buttons="true"
    :persistent="disabled"
    :allow-escape="false"
    :centered="true"
    :title="depositFlowActive ? 'Deposit Funds' : ''"
    persistent-on-off
    @close="close"
  >
    <div class="h-full">
      <TransactionHash
        v-if="showTransactionReceiptComponent"
        :hash="hash"
        @on-success="(receipt) => handleTransactionSuccess(receipt, tipArtistFlow ? tipArtistToAddress : address)"
        @on-fail="handleTransactionFail"
      />

      <!-- Countdown -->
      <div
        v-if="showCountdownTimer && !(transactionComplete)"
        class="bg-[#F1F5F9] p-3 text-center mb-3 flex space-x-2 items-center justify-center rounded-md"
      >
        <Icon
          icon="ClockIcon"
          class="w-4 md:w-5 h-4 md:h-5"
        />
        <Body
          size="sm"
          black-text
          class="font-mono"
        >
          {{ formattedDate }}
        </Body>
      </div>

      <div
        v-else-if="transactionComplete"
        class="flex flex-col w-full gap-0 md:gap-0 px-4 pb-4 lg:pb-8"
      >
        <div
          v-if="!depositFlowActive"
          class="flex flex-row gap-2 justify-center text-3xl lg:text-4xl font-medium text-black"
          black-text
        >
          <div class="flex flex-col justify-center">
            <div class="w-6 h-6 lg:w-8 lg:h-8 rounded-full bg-[#1B6BFF] justify-center items-center flex">
              <Icon
                icon="CheckIcon"
                class="w-3 h-3 lg:w-4 lg:h-4 text-white stroke-[6px] font-bold"
              />
            </div>
          </div>{{ tipArtistFlow ? 'Tip Sent': 'Collected!' }}
        </div>

        <Body
          v-if="transactionUrl"
          size="sm"
          black-text
        >
          View <span><a
            :href="transactionUrl"
            target="_blank"
            class="inline-block p-1 cursor-pointer underline"
          >transaction</a></span>on the blockchain.
        </Body>
      </div>

      <!-- Deposit flow -->
      <div
        v-if="depositFlowActive"
        class="pb-3"
      >
        <div
          class="bg-slate-100 rounded-xl p-5"
          :class="depositFundsByAdmin?'mb-4':''"
        >
          <Body
            size="sm"
            black-text
          >
            Your current Remx balance is {{ balanceInEth }} ETH which is approximately {{ balanceInUsd }} USD.
          </Body>
        </div>
      </div>

      <!-- Collection flow -->
      <div v-else-if="collection">
        <div class="border border-brand-border rounded-[12px] p-2 md:p-2 flex w-full gap-2 md:gap-4 mb-3">
          <div class="basis-[60px] md:basis-[90px] grow-0 shrink-0 w-0">
            <img
              v-if="!isMovieFile"
              class="aspect-square object-cover rounded-xl w-full bg-slate-100"
              :src="snapshot.startsWith('0x') ? getImage(snapshot, {resize: {width: 200, height: 200, fit: 'cover'} }) : snapshot"
            >

            <video
              v-else
              class="aspect-square object-cover rounded-xl w-full bg-slate-100"
              :src="getVideo(snapshot)"
              autoplay
              muted
              loop
              playsinline
              webkit-playsinline
            />
          </div>

          <div class="text-left flex flex-col justify-center grow gap-1 md:gap-2">
            <div>
              <Body
                black-text
                class="font-medium"
              >
                {{ dropDetails.title }} {{ tokenId? `#${tokenId}` : '' }}
              </Body>

              <Body
                v-if="collection.description"
                size="sm"
              >
                @{{ artistName }}
              </Body>
            </div>

            <Body
              v-if="dropDetails.numberOfPerks > 0"
              size="sm"
            >
              {{ dropDetails.numberOfPerks }} {{ dropDetails.numberOfPerks === 1 ? 'perk' : 'perks' }} included
            </Body>
          </div>
        </div>
      </div>

      <!-- Profile flow -->
      <div v-if="profile">
        <div class="border border-brand-border rounded-2xl p-2 md:p-6 flex w-full gap-2 md:gap-4 mb-3">
          <div class="basis-[60px] md:basis-[140px] grow-0 shrink-0 w-0">
            <img
              class="aspect-square object-cover rounded-xl w-full bg-slate-100"
              :src="profile.icon ? getImage(profile.icon, {resize: {width: 200, height: 200, fit: 'cover'} }) : BlankProfileIcon"
            >
          </div>

          <div class="text-left flex flex-col justify-between grow gap-1 md:gap-2">
            <div>
              <Title
                title-element="section-subtitle"
                class="leading-[1.1em]"
              >
                {{ depositFundsByAdmin ? 'Deposit' : 'Tip' }} for: {{ formatUsername(profile.displayName) }}
              </Title>
            </div>
          </div>
        </div>
      </div>

      <!-- DropPricing when selectPriceFlowActive true-->
      <div
        v-if="selectPriceFlowActive"
        class="h-full"
      >
        <DropPricing
          :loading="false"
          :dollar-amounts="getDropPricingAmounts()"
          :custom-amount="depositFlowActive ? 55 : 5"
          :custom-amount-increments="depositFlowActive ? 5 : 1"
          :end-complete="endComplete"
          :popular="depositFlowActive ? 10 : 2"
          :button-disabled="false"
          :title="depositFlowActive || depositFundsByAdmin || (!depositFlowActive && isOwner) ? '' : isProfileView ? 'Thank the creator with a tip.' : 'Choose your price - Thank the creator with a tip.'"
          :button-title="isProfileView ? 'Tip' :'Collect'"
          description=""
          :chain-id="chainId"
          :floor-price="floorPrice"
          :hide-popular="depositFlowActive || depositFundsByAdmin"
          :secondary-button-text="'Wallet'"
          :is-deposit-flow="depositFlowActive"
          :is-tip-artist-flow="tipArtistFlow"
          :single-price="(!depositFlowActive && isOwner && !isPaid)? 0 : null"
          :is-paid="isPaid"

          @claim="handlePriceSelectedDigitalWallet"
          @claim-with-credit-card="handlePriceSelectedCreditCard"
          @claim-with-digital-wallet="handlePriceSelectedDigitalWallet"
          @claim-with-balance="handlePriceSelectedWithBalance"
        />
      </div>

      <!-- Minting mintFlowActive is active-->
      <div
        v-else-if="mintFlowActive"
        class="flex flex-row justify-start"
      >
        <div class="flex flex-col justify-center items-center w-full h-full">
          <PriceSummary
            v-if="supportAmount !== 0 && !transactionComplete && !transactionUrl"
            class="mb-3 w-full"
            :price="supportAmount"
            :chain-id="chainId"
            :showArrow="false"
          />

          <!-- Crossmint component -->
          <div
            v-if="payingWithCrossmintActive && !transactionSuccessful && crossmintMintConfigReady"
            class="w-full h-full flex flex-col gap-2"
          >
            <CrossmintPaymentElement
              :environment="crossmintEnvironment"
              :collection-id="crossmintClientId"
              :project-id="crossmintProjectId"
              :mint-config="crossmintMintConfig"
              :recipient="{
                email,
                ...(depositFlowActive? {wallet: depositToAddress}: wallet? {wallet} : {}),
              }"
              :ui-config="{
                borderRadius: '4px',
                colors: {
                  background: '#FFFFFF',
                  backgroundSecondary: '#FFFFFF'
                },
                hideCardForm: false,
              }"
              @event="onCrossmintEvent"
            />
          </div>

          <!-- Warning banners -->
          <div
            v-if="(payingWithDigitalWalletActive || payingWithBalanceActive) && supportAmount !== 0"
            class="w-full"
          >
            <MintingBanner
              v-if="isRemxWallet && !payingWithBalanceActive"
              priority="announcement-minting"
            >
              Please select a wallet to continue.
            </MintingBanner>

            <MintingBanner
              v-else-if="!isConnected && !payingWithBalanceActive"
              priority="announcement-minting"
              class="mb-3 text-[#307DDE]"
            >
              Please connect address {{ mintToAddress }} in your wallet to continue.
            </MintingBanner>

            <MintingBanner
              v-else-if="!connectorIsDefaultWallet && !depositFlowActive && !payingWithBalanceActive"
              priority="warning"
              class="mb-3 text-[#307DDE]"
            >
              {{ `Address ${address} connected. Please switch to address ${truncateMiddle(mintToWallet.address)} in your wallet` }}
            </MintingBanner>

            <MintingBanner
              v-else-if="switchChainActive && !payingWithBalanceActive"
              class="mb-3 text-[#307DDE]"
            >
              {{ `Please switch your wallet to the ${getChainInfo(chainId).displayName} network${!floorPrice ? '.' : ' or use a credit card to pay.'}` }}
            </MintingBanner>

            <MintingBanner
              v-else-if="isWaitingForTransaction && !transactionComplete && !showTransactionReceiptComponent && !payingWithBalanceActive"
              class="mb-3 text-[#307DDE]"
            >
              Please confirm this transaction in your wallet.
            </MintingBanner>

            <MintingBanner
              v-else-if="insufficientFunds && !depositFlowActive && payingWithBalanceActive"
              priority="announcement-minting"
              class="mb-3 text-[#307DDE]"
            >
              {{ `You have insufficient funds for this transaction. Current balance is $${currentBalanceUsd}. Please deposit funds or pay another way.` }}
            </MintingBanner>

            <MintingBanner
              v-else-if="insufficientFunds && !depositFlowActive && payingWithDigitalWalletActive"
              class="mb-3 text-[#307DDE]"
            >
              {{ `You have insufficient funds in your wallet for this transaction.` }}
            </MintingBanner>

            <MintingBanner
              v-else-if="walletError"
              class="mb-3 text-[#307DDE]"
            >
              {{ walletErrorName === 'EstimateGasExecutionError' ? `Insufficient funds in wallet or incorrect network. Please add funds or switch to ${getChainInfo(chainId).displayName} network` : walletError }}
            </MintingBanner>
          </div>

          <!-- Select wallet -->
          <div
            v-if="shouldShowSelectWallet"
            class="flex flex-row w-full py-4"
          >
            <SelectConnectedWallet
              :chain-id="chainId"
              @updated-wallet="handleDefaultWalletChange"
              @changing-wallet="()=>{}"
              @navigating-to-profile="close"
            />
          </div>

          <!-- GLOBAL BUTTONS -->
          <div
            v-if="(!waitingOnMutations && !payingWithCrossmintActive) || transactionComplete || needToConfirm"
            class="flex w-full flex-col gap-2"
          >
            <div class="w-full">
              <div
                v-if="!transactionComplete && !payingWithCrossmintActive && getDigitalButtonText() !== ''"
                class="flex space-x-4 w-full"
              >
                <GlobalButton
                  class="w-full"
                  priority="primary-light"
                  @click="handleCheckActiveConnector"
                >
                  <span>
                    {{ getDigitalButtonText() }}
                  </span>
                  <span v-if="showSpinner">
                    <LoadingIcon />
                  </span>
                </GlobalButton>
              </div>

              <div
                v-if="transactionComplete"
                class="flex w-full flex-col gap-2 pt-2 lg:pt-6"
              >
                <GlobalButton
                  v-if="!depositFlowActive && !tipArtistFlow"
                  class="rounded-lg"
                  full-width
                  justify="between"
                  @click="handleTwitterShare"
                >
                  <p
                    class="w-full ml-10"
                  >
                    Share to X
                  </p>

                  <div>
                    <XIcon class="w-4 h-4 lg:w-5 lg:h-5" />
                  </div>
                </GlobalButton>

                <GlobalButton
                  class="rounded-lg"
                  priority="secondary-light"
                  full-width
                  @click.prevent="close"
                >
                  Close
                </GlobalButton>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Pay another way -->
      <div
        v-if="!depositFlowActive && ((supportAmount !== 0 && !transactionComplete && !isWaitingForTransaction && !payingWithBalanceActive && !isPaying) || (supportAmount !== 0 &&isWaitingForTransaction && !transactionComplete && !showTransactionReceiptComponent && !payingWithBalanceActive && !isPaying))"
        class="flex flex-row pt-4 w-full justify-center"
      >
        <Body
          size="xs"
          black-text
        >
          Pay <button
            class="underline"
            @click="resetAll"
          >
            another way
          </button> instead.
        </Body>
      </div>
    </div>
  </Modal>
</template>

<script setup lang="ts">
import { type PropType, computed, ref, onUnmounted, watch, type Ref, type ComputedRef } from 'vue'
import { useChainId, useAccount as useWagmiAccount, useSendTransaction, useConnect, useSwitchChain, useDisconnect } from 'use-wagmi'
import { formatEther, parseEther, parseUnits, createPublicClient, http } from 'viem'
import { base, baseSepolia } from 'viem/chains'
import { useRouter } from 'vue-router'

import { CrossmintPaymentElement, useCrossmintEvents } from '@crossmint/client-sdk-vue-ui'
import type { CrossmintEvent } from '@crossmint/client-sdk-base'
import { ethers } from 'ethers'
import buyNFTAbi from '#abi/buyNFT.json'
import ownerOfAbi from '#abi/ownerOf.json'
import tipArtistAbi from '#abi/tipArtist.json'
import depositFundsAbi from '#abi/depositFunds.json'

import { getChainInfo, truncateMiddle, formatUsername } from '#utils'
import { formatFutureDate } from '#utils/formatting'

import useAccount from '#composables/use-account'
import useImage from '#composables/use-image'
import useLogin from '#composables/use-login'
import useSnackbar from '#composables/use-snackbar'
import { releaseToken, reserveToken, claimToken, purchaseToken, tipArtist, getTokenSupply, purchaseComplete } from '#composables/use-mint/mint.service'
import { useMutation, useLazyQuery } from '#composables/use-apollo'
import { useExchangePrices } from '#composables/use-exchange-prices'
import { useBalance } from '#composables/use-balance'
import { useAccountActivity } from '#composables/use-account-activity'

import type {
  AccountActivity_accountActivity,
  AccountActivity_accountActivity_TokenPurchaseConfirming,
  AccountActivity_accountActivity_TokenPurchaseFailed,
  AccountActivity_accountActivity_TokenPurchasePending,
  AccountActivity_accountActivity_TokenPurchaseSuccess,
} from '#graphql/types/AccountActivity'

import { GET_TOKEN_TRANSACTION_HASH } from '#queries/GetTokenTransactionHash'
import type { GetTokenTransactionHash, GetTokenTransactionHashVariables } from '#graphql/types/GetTokenTransactionHash'

import { RECORD_TIP } from '#mutations/RecordTip'
import type { RecordTip, RecordTipVariables } from '#graphql/types/RecordTip'

import type { FundingSource } from '#graphql/types/globalTypes'
import { DropType } from '#graphql/types/globalTypes'

import DropPricing from '#components/drop/drop-pricing.vue'
import Body from '#components/typography/body'
import Title from '#components/typography/title'
import GlobalButton from '#components/global/global-button'
import LoadingIcon from '#components/icons/loading-icon.vue'
import Modal from '#components/modal'
import Banner from '#components/banner/banner.vue'
import Icon from '#components/icon.vue'
import SelectConnectedWallet from '#components/minting/select-connected-wallet.vue'
import TransactionHash from '#components/profile/transaction-hash.vue'
import MintingBanner from '#components/minting/minting-banner.vue'
import XIcon from '#components/icons/x-platform-icon.vue'

import BlankProfileIcon from '#assets/default_avatar.png'
import PriceSummary from './price-summary.vue'

const props = defineProps({
  isOpen: {
    type: Boolean,
    default: false,
  },

  collection: {
    type: Object as PropType<any | null>,
    default: null,
  },

  dropType: {
    type: String as PropType<DropType | null>,
    default: null,
  },

  dropId: {
    type: String as PropType<string | null>,
    default: null,
  },

  profile: {
    type: Object as PropType<any | null>,
    default: null,
  },

  showCountdownTimer: {
    type: Boolean,
    default: true,
  },

  depositFundsByAdmin: {
    type: Boolean,
    default: false,
  },

  directTipToArtist: {
    type: Boolean,
    default: false,
  },
})

const emit = defineEmits(['close', 'successful-mint'])

const { id:accountId, email, profileSlug: accountSlug, twitterUsername } = useAccount()
const router = useRouter()
const { getImage, getVideo } = useImage()
const { address, connector:activeConnector, isConnected } = useWagmiAccount()
const chain = useChainId()
const { account: useLoginAccount, accountId:activityAccountId } = useLogin()
const { sendTransaction } = useSendTransaction()
const { switchChainAsync } = useSwitchChain()
const { connectAsync, connectors } = useConnect()
const { disconnectAsync } = useDisconnect()
const { currentBalanceEth, currentBalanceUsd, waitForBalance } = useBalance()
const { addSnack } = useSnackbar()
const { ethPrice } = useExchangePrices()
const { accountActivityOnResult } = useAccountActivity()

const publicClient = createPublicClient({
  chain: import.meta.env.VITE_CROSSMINT_ENVIRONMENT === 'production' ? base : baseSepolia,
  transport: http(),
})

const activity:Ref<(
  AccountActivity_accountActivity_TokenPurchaseConfirming
  | AccountActivity_accountActivity_TokenPurchaseFailed
  | AccountActivity_accountActivity_TokenPurchasePending
  | AccountActivity_accountActivity_TokenPurchaseSuccess
)[]> = ref([])

const crossmintEnvironment = import.meta.env.VITE_CROSSMINT_ENVIRONMENT.toLowerCase()
const { listenToMintingEvents } = useCrossmintEvents({
  environment: crossmintEnvironment,
})

type HexString = `0x${string}`

const selectPriceFlowActive = ref(true)
const mintFlowActive = ref(false)
const waitingOnMutations = ref(false)

const payingWithCrossmintActive = ref(false)
const payingWithDigitalWalletActive = ref(false)
const payingWithBalanceActive = ref(false)

const fundingSource: ComputedRef<FundingSource> = computed(() => {
  if (payingWithCrossmintActive.value) {
    return 'crossmint' as FundingSource
  } else if (payingWithDigitalWalletActive.value) {
    return 'wallet' as FundingSource
  } else {
    return 'balance' as FundingSource
  }
})

const showSpinner = ref(false)
const supportAmount = ref(0)
const reservedTokenId = ref()
const transactionUrl = ref('')
const transactionComplete = ref(false)
const transactionSuccessful = ref(false)
const transactionCrossmintSuccessful = ref(false)
const walletError = ref<string | null>(null)
const walletErrorName = ref<string | null>(null)
const hash = ref<HexString | undefined>(undefined)
const showTransactionReceiptComponent = ref(false)
const purchaseDetails = ref()
const totalFiatItemPrice = ref<string | number | null>(null)
const totalGasFee = ref<string | number | null>(null)
const currencyCrossmint = ref<string | null>(null)
const isPaying = ref(false)
const weiPrepare = ref(0)
const tipArtistChainId  = ref(parseInt(import.meta.env.VITE_DEFAULT_CHAIN_ID, 10) || 84532)
const dropPerks = ref<any[]>([])
const isWaitingForTransaction = ref(false)
const needToConfirm = ref(true)

const balanceInEth = computed(()=> currentBalanceEth.value)
const balanceInUsd = computed(()=> currentBalanceUsd.value)
const isOwner = computed(() => props.collection ? accountSlug.value === props.collection.collaborators[0].profile.username : false)

const insufficientFunds = computed(() => balanceInUsd.value < supportAmount.value)
const tipArtistFlow = computed(() => props.profile ? true : false)
const isProfileView = computed(() => router.currentRoute.value.name === 'profile' || router.currentRoute.value.name === 'profile-moments' || router.currentRoute.value.name === 'profile-drops' || router.currentRoute.value.name === 'profile-sponsorships' || router.currentRoute.value.name === 'profile-collected')
const disabled = computed(() => isWaitingForTransaction.value || waitingOnMutations.value)
const hasOnlyRemxWallet = computed(() => useLoginAccount.value?.wallets.length === 1 && useLoginAccount.value?.wallets[0].type === 'remx')
const connectorIsDefaultWallet = computed(() => (address.value?.toLowerCase() === mintToAddress.value))
const switchChainActive = computed(() => isConnected && currentChainInfo.value.id !== chainId.value && connectorId.value.toLowerCase() !== 'crossmint' && !isRemxWallet.value && !payingWithCrossmintActive.value && supportAmount.value > 0)
const floorPrice = computed(() => supportAmount.value || props.collection?.auction?.floorPrice || 0)
const currentChainInfo = computed(() => getChainInfo(chain.value))
const isPaid = computed(() => props.collection?.auction?.floorPrice > 0)

const chainId = computed(() => props.collection? props.collection.chainId : tipArtistChainId.value)
const contractId = computed(() => props.collection?.contractId || '')
const endDate = computed(() => props.collection?.auction?.endDate || '')
const crossmintConfig = computed(() => props.collection? props.collection?.crossmintConfig:null)
const depositFlowActive = computed(()=> (!props.collection && !props.profile) || (props.profile && props.depositFundsByAdmin))
const crossmintClientId = computed(() => depositFlowActive.value ? import.meta.env.VITE_CROSSMINT_DEPOSIT_FUNDS_COLLECTION_ID : props.profile? import.meta.env.VITE_CROSSMINT_TIP_ARTIST_COLLECTION_ID :crossmintConfig?.value.clientId)

const crossmintProjectId = computed(() => import.meta.env.VITE_CROSSMINT_PROJECT_ID)
const artistName = computed(() => props.collection && props.collection.collaborators.length ? formatUsername(props.collection.collaborators[0].profile.displayName) : props.profile ? formatUsername(props.profile.displayName) : 'Anonymous' )
const mintToAddress = computed(() => useLoginAccount.value && useLoginAccount.value.defaultWallet)
const mintToWallet = computed(() => useLoginAccount.value && useLoginAccount.value.wallets.find((wallet) => wallet.address === mintToAddress.value))
const tipArtistFromAddress = computed(() => mintToAddress.value)
const tipArtistToAddress = computed(() => props.profile?.accountId)
const depositFromAddress = computed(() => mintToAddress.value)
const depositToAddress = computed(() =>  props.profile?.accountId || accountId.value) //TODO: admins should be able to deposit to another users account
const wallet = computed(() => tipArtistFlow.value ? tipArtistToAddress.value : mintToWallet.value.address)
const isRemxWallet = computed(() => (!mintToWallet.value?.connectionType && mintToWallet.value?.type === 'remx'))
const connectorForMintToWallet = computed(() => connectors.value.find((connector) => connector.name.toLowerCase() === localStorageConnectionType.value?.toLowerCase()))

const dropDetails = computed(() => ({
  title: props.collection ? props.collection.name : '',
  price: props.collection ? props.collection.auction?.floorPrice || supportAmount?.value || 0 : 0,
  numberOfPerks: dropPerks.value?.length || 0,
}))

const snapshot = computed(() => props.collection.metadata?.assetFile || '')
const imageType = computed(() => props.collection.metadata?.assetType || '')
const isMovieFile = computed(() => imageType.value === 'video/mp4' || imageType.value === 'video/webm' || imageType.value === 'video/quicktime')

const tokenId = computed(() => purchaseDetails?.value ? purchaseDetails?.value.tokenId:0)
const connectorId = computed(() => activeConnector?.value?.id || '')

const crossmintMintConfig = computed(() => depositFlowActive.value ? depositConfig.value : tipArtistFlow.value? tipConfig.value : mintConfig.value)
const crossmintMintConfigReady = computed(() => {
  if (depositFlowActive.value && weiPrepare.value !== 0) {
    return true
  } else if (tipArtistFlow.value && weiPrepare.value !== 0) {
    return true
  } else if (purchaseDetails.value) {
    return true
  } else {
    return false
  }
})

const localStorageConnectionType = computed(() => localStorage.getItem('remx-connectionType'))

const mintConfig = computed(() => ({
  type: 'erc-721',
  totalPrice: purchaseDetails.value? formatEther(purchaseDetails.value.price): 0,
  _collection: contractId.value,
  tokenId: tokenId.value,
  expiryBlock: purchaseDetails.value ? purchaseDetails.value.expiryBlock : 0,
  signature: purchaseDetails.value ? purchaseDetails.value.signature : '',
  price: purchaseDetails.value? formatEther(purchaseDetails.value.price) : 0,
}))

const tipConfig = computed(() => ({
  type: 'erc-721',
  totalPrice: weiPrepare.value.toString(),
  price: weiPrepare.value.toString(),
}))

const depositConfig = computed(() => ({
  type: 'erc-721',
  totalPrice: weiPrepare.value.toString(),
  price: weiPrepare.value.toString(),
}))

const shouldShowSelectWallet = computed(() => (isRemxWallet.value && supportAmount.value !== 0 && payingWithDigitalWalletActive.value))

const getDropPricingAmounts = () => {
  if (depositFlowActive.value) {
    return  [10,25,50]
  } else if (tipArtistFlow.value) {
    return [1, 2, 4]
  } else if (isPaid.value) {
    return [floorPrice.value]
  } else if ((!depositFlowActive.value && (isOwner.value && !isPaid.value))) {
    return [0]
  } else {
    return [0, 1, 2, 4]
  }
}

const handleConnect = async () => {
  if (isConnected.value) {
    await disconnectAsync()
  }

  if (connectorForMintToWallet.value) {
    await connectAsync({ connector: connectorForMintToWallet.value })

    if (activeConnector.value) {
      localStorage.setItem('remx-connectionType', activeConnector.value.name)
      handleDigitalButtonClick()
    }
  }
}

const handleSwitchChain = async () => {
  if (connectorForMintToWallet.value) {
    await switchChainAsync({ chainId: chainId.value })
    handleCheckActiveConnector()
  }
}

const handleCheckActiveConnector = () => {
  if (!activeConnector.value && supportAmount.value !== 0 && payingWithDigitalWalletActive.value) {
    // open connection dialog
    console.log('handleCheckActiveConnector open connection dialog with handleConnect()')
    handleConnect()
  } else {
    if (activeConnector.value) {
      localStorage.setItem('remx-connectionType', activeConnector.value.name)
    }

    handleDigitalButtonClick()
  }
}

const getDigitalButtonText = () => {
  showSpinner.value = false
  if (hasOnlyRemxWallet.value && payingWithDigitalWalletActive.value && supportAmount.value > 0) {
    return 'Connect a digital wallet'
  }  else if (switchChainActive.value && !payingWithCrossmintActive.value && !payingWithBalanceActive.value) {
    return `Switch to ${ getChainInfo(chainId.value).displayName }`
  } else if (walletError.value) {
    return `Refresh`
  } else if (payingWithBalanceActive.value && currentBalanceUsd.value < supportAmount.value) {
    return 'Refresh'
  } else if (payingWithCrossmintActive.value) {
    return ''
  } else if (tipArtistFlow.value && payingWithBalanceActive.value) {
    showSpinner.value = true
    return 'Confirming transaction'
  } else if (payingWithBalanceActive.value || supportAmount.value === 0) {
    showSpinner.value = true
    return 'Confirming transaction'
  } else if (isWaitingForTransaction.value && !transactionComplete.value && !showTransactionReceiptComponent.value) {
    return ''
  } else if (payingWithDigitalWalletActive.value && !connectorIsDefaultWallet.value) {
    return ''
  }  else if (!isConnected.value || !connectorIsDefaultWallet.value) {
    return ''
  } else {
    showSpinner.value = true
    return 'Confirming transaction'
  }
}

const handleDigitalButtonClick = async () => {
  if (switchChainActive.value) {
    handleSwitchChain()
  } else if (walletError.value) {
    refresh()
  } else if (payingWithBalanceActive.value && currentBalanceUsd.value < supportAmount.value) {
    resetAll()
  } else if (payingWithBalanceActive.value && !insufficientFunds.value) {
    handleConfirmWithBalance()
  }  else if (payingWithBalanceActive.value && insufficientFunds.value) {
    resetAll()
  } else if (depositFlowActive.value && !switchChainActive.value) {
    handleDepositFunds()
  } else if (tipArtistFlow.value && !switchChainActive.value) {
    handleTipArtist()
  } else if (supportAmount.value === 0) {
    handleConfirmWithWallet()
  } else if (!isConnected.value || !connectorIsDefaultWallet.value) {
    handleConnect()
  } else if (hasOnlyRemxWallet.value && supportAmount.value !== 0) {
    navigateToProfile()
    close()
  } else {
    handleConfirmWithWallet()
  }
}

const handleDefaultWalletChange = async (address: string) => {
  if (reservedTokenId.value && !tipArtistFlow.value) {
    await releaseToken(reservedTokenId.value)
    reservedTokenId.value = await reserveToken(props.collection?.id, address, '')
  }
}

const navigateToProfile = () => {
  router.push({ name: 'profile-edit', params: { profileSlug: useLoginAccount.value.slug }, hash: '#wallets' })
}

const prepareTransaction = async () => {
  const {
    contractId,
    tokenId,
    expiryBlock,
    signature,
    price,
  } = purchaseDetails.value

  const contract = new ethers.Contract(revenueSplitterAddress.value, buyNFTAbi)
  const wei = ethers.BigNumber.from((price))

  mintTransaction = await contract.populateTransaction.buyNFT(contractId, tokenId, expiryBlock, signature)
  mintTransaction.from = mintToAddress.value
  mintTransaction.value = wei
}

const prepareTipArtistTransaction = async (accountId, amount) => {
  const contract = new ethers.Contract(revenueSplitterAddress.value, tipArtistAbi)
  const wei = ethers.BigNumber.from(amount)

  mintTransaction = await contract.populateTransaction.tipArtist(accountId)
  mintTransaction.from = tipArtistFromAddress.value
  mintTransaction.value = wei
}

const prepareTipArtist = async () => {
  const ethExchangeRate = ethPrice.value
  const wei = parseEther(`${supportAmount.value / ethExchangeRate}`)

  weiPrepare.value = parseFloat(formatEther(wei))

  return prepareTipArtistTransaction(tipArtistToAddress.value, wei)
}

const prepareDepositFundsTransaction = async (accountId, amount) => {
  const contract = new ethers.Contract(revenueSplitterAddress.value, depositFundsAbi)
  const wei = ethers.BigNumber.from(amount)

  mintTransaction = await contract.populateTransaction.depositFunds(depositToAddress.value)
  mintTransaction.from = depositFromAddress.value
  mintTransaction.value = wei
}

const prepareDepositFunds = async () => {
  const ethExchangeRate = ethPrice.value
  const wei = parseEther(`${supportAmount.value / ethExchangeRate}`)

  weiPrepare.value = parseFloat(formatEther(wei))

  return prepareDepositFundsTransaction(depositToAddress.value, wei)
}

const handleTipArtist = async () => {
  try {
    await prepareTipArtist()

    waitingOnMutations.value = true
    isWaitingForTransaction.value = true

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const tx = await sendTransaction(mintTransaction as any, {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
      onError: (error: any) => {
        console.error('error sending transaction', { error })

        walletError.value = error.shortMessage
        walletErrorName.value = error.name
        isWaitingForTransaction.value = false
        waitingOnMutations.value = false

        if (error.name === 'EstimateGasExecutionError') {
          addSnack({ message: 'Insufficient funds for transaction. Please add funds to your wallet.', color: 'negative' })
        } else {
          addSnack({ message: error.shortMessage, color: 'negative' })
        }

        validateWalletError()
      },
      onSuccess: (transactionHash) => {
        hash.value = transactionHash
        showTransactionReceiptComponent.value = true
      },
    })
  } catch (error) {
    console.error('error sending transaction', { error })
  }
}

const handleDepositFunds = async () => {
  try {
    await prepareDepositFunds()

    waitingOnMutations.value = true
    isWaitingForTransaction.value = true

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const tx = await sendTransaction(mintTransaction as any, {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
      onError: (error: any) => {
        console.error('error sending transaction', { error })

        walletError.value = error.shortMessage
        walletErrorName.value = error.name
        isWaitingForTransaction.value = false
        waitingOnMutations.value = false
        if (error.name === 'EstimateGasExecutionError') {
          addSnack({ message: 'Insufficient funds for transaction. Please add funds or use the Credit Card option.', color: 'negative' })
        } else {
          addSnack({ message: error.shortMessage, color: 'negative' })
        }
        validateWalletError()
      },
      onSuccess: (transactionHash) => {
        hash.value = transactionHash
        showTransactionReceiptComponent.value = true
      },
    })
  } catch (error) {
    console.error('error sending transaction', { error })
  }
}

// const handlePriceSelectedType = (type) => {
//   console.log('handlePriceSelectedType', type)
//   if (type === 'paid') {
//     payingWithDigitalWalletActive.value = false
//     payingWithCrossmintActive.value = false
//     payingWithBalanceActive.value = false
//     selectPriceFlowActive.value = true
//   }
// }

const handlePaidBack = () => {
  payingWithDigitalWalletActive.value = false
  payingWithCrossmintActive.value = false
  payingWithBalanceActive.value = false
  selectPriceFlowActive.value = true
}

const handlePriceSelectedWithBalance = async (amount = 0) => {
  payingWithBalanceActive.value = true
  await handlePriceSelected(amount)

  waitingOnMutations.value = false

  if (currentBalanceUsd.value > supportAmount.value) {
    handleDigitalButtonClick()
  }
}

const handlePriceSelectedDigitalWallet = async (amount = 0) => {
  if (hasOnlyRemxWallet.value && amount !== 0) {
    navigateToProfile()
    close()

    return
  }

  await handlePriceSelected(amount)

  payingWithDigitalWalletActive.value = true

  handleCheckActiveConnector()
}

const handlePriceSelectedCreditCard = async (amount = 0) => {
  payingWithCrossmintActive.value = true
  await handlePriceSelected(amount)
  handleEmbeddedCrossmint()
}

/**
 * reserves a token and waits for the machine to transition correctly until confirming it
 * we watch the incoming confirm token to open the mint modal
 */
const handlePriceSelected = async (amount = 0) => {
  supportAmount.value = amount
  waitingOnMutations.value = true
  selectPriceFlowActive.value = false
  mintFlowActive.value = true

  if (props.collection) {
    const tokenSupplyResult = await getTokenSupply(props.collection?.id)
    const remaining = tokenSupplyResult ? tokenSupplyResult.remaining : 1

    if (remaining === 0) {
      addSnack({ message: 'This collection has run out of items. Please try again later.', color: 'negative' })
      close()

      return
    }

    reservedTokenId.value = await reserveToken(props.collection?.id, mintToAddress.value, '')
  }

  waitingOnMutations.value = false
}

let mintTransaction

const revenueSplitterAddress = computed(() => {
  const chainIdValue = tipArtistFlow.value? tipArtistChainId.value : chainId.value || import.meta.env.DEFAULT_CHAIN_ID

  return JSON.parse(import.meta.env.VITE_REVENUESPLITTER)[chainIdValue].toLowerCase()
})

const handleEmbeddedCrossmint = async () => {
  payingWithDigitalWalletActive.value = false
  payingWithCrossmintActive.value = true

  if (depositFlowActive.value) {
    prepareDepositFunds()
  } else if (!tipArtistFlow.value) {
    const purchaseTokenResult = await purchaseToken(reservedTokenId.value, supportAmount.value, null, fundingSource.value)
    purchaseDetails.value = purchaseTokenResult
    await prepareTransaction()
  } else {
    prepareTipArtist()
  }
}

const handleConfirmWithWallet = async () => {
  waitingOnMutations.value = true
  isWaitingForTransaction.value = true

  if (supportAmount.value === 0) {
    await claimToken(reservedTokenId.value)

    //addSnack({ message: 'Transaction successful', color: 'positive' })
    emit('successful-mint')

    waitingOnMutations.value = false
    isWaitingForTransaction.value = false
    transactionSuccessful.value = true
    transactionComplete.value = true

    //close()
  } else {
    const purchaseTokenResult = await purchaseToken(reservedTokenId.value, supportAmount.value, null, 'wallet')
    purchaseDetails.value = purchaseTokenResult

    await prepareTransaction()

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    sendTransaction(mintTransaction as any, {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      onError: (error: any) => {
        walletError.value = error.shortMessage
        walletErrorName.value = error.name
        isWaitingForTransaction.value = false
        waitingOnMutations.value = false

        // if (error.name === 'EstimateGasExecutionError') {
        //   addSnack({ message: 'Insufficient funds for transaction. Please add funds or use the Credit Card option.', color: 'negative' })
        // } else {
        //   addSnack({ message: error.shortMessage, color: 'negative' })
        // }
        addSnack({ message: error.shortMessage, color: 'negative' })
        validateWalletError()
      },
      onSuccess: (transactionHash) => {
        hash.value = transactionHash
        showTransactionReceiptComponent.value = true
      },
    })
  }
}

const validateWalletError = () => {
  if (walletError.value === 'User rejected the request.') {
    console.log('User rejected the request. Should refresh now')
    // refresh()
  }
}

const handleConfirmWithBalance = async () => {
  waitingOnMutations.value = true
  isWaitingForTransaction.value = true

  try {
    const { url } = getChainInfo(chainId.value)

    if (tipArtistFlow.value) {
      const ethExchangeRate = ethPrice.value
      const wei = supportAmount.value / ethExchangeRate
      const purchaseTokenResult = await tipArtist(tipArtistFromAddress.value, tipArtistToAddress.value, supportAmount.value, wei, chainId.value, fundingSource.value)

      if (typeof purchaseTokenResult.hash === 'string' ) {
        transactionUrl.value = `https:${url}/tx/${purchaseTokenResult.hash}`
      } else {
        walletError.value = 'Error using balance to tip, refresh and try again'
        walletErrorName.value = 'Error using balance to tip, refresh and try again'
      }
    } else {
      const purchaseTokenResult = await purchaseToken(reservedTokenId.value, supportAmount.value, mintToAddress.value, fundingSource.value)
      purchaseDetails.value = purchaseTokenResult

      if ( typeof purchaseTokenResult.result === 'string' ) {
        transactionUrl.value = `https:${url}/tx/${purchaseTokenResult.result}`
      } else {
        walletError.value = 'Error using balance to purchase, refresh and try again'
        walletErrorName.value = 'Error using balance to purchase, refresh and try again'
      }
    }

    validateWalletError()

    emit('successful-mint')

    waitingOnMutations.value = false
    isWaitingForTransaction.value = false
    transactionSuccessful.value = true
    transactionComplete.value = true

    await waitForBalance()
  } catch (error) {
    console.error('error sending transaction', { error })

    walletError.value = 'Error using balance'
    walletErrorName.value = 'Error using balance'

    isWaitingForTransaction.value = false
    waitingOnMutations.value = false
    payingWithBalanceActive.value = false

    validateWalletError()
  }
}

const onCrossmintEvent = async (event: CrossmintEvent) => {
  switch (event.type) {
    case 'quote:status.changed':
      // eslint-disable-next-line no-case-declarations
      const { totalPrice, lineItems } = event.payload

      totalFiatItemPrice.value = lineItems[0].price.amount
      currencyCrossmint.value = totalPrice.currency.toUpperCase()
      isPaying.value = false
      totalGasFee.value = lineItems[0].gasFee?.amount ?? 0

      break
    case 'payment:process.started':
      isPaying.value = true
      isWaitingForTransaction.value = true

      break
    case 'payment:process.succeeded':
      // eslint-disable-next-line no-case-declarations
      const { orderIdentifier } = event.payload

      listenToMintingEvents({ orderIdentifier }, async (event) => {
        switch (event.type) {
          case 'transaction:fulfillment.succeeded':
            // eslint-disable-next-line no-case-declarations
            const  { txId } = event.payload

            await handleTransactionSuccess({ transactionHash: txId }, tipArtistFlow.value ? tipArtistToAddress.value : mintToAddress.value)

            payingWithCrossmintActive.value = false
            transactionComplete.value = true
            transactionCrossmintSuccessful.value = true
            isPaying.value = false
            break
          case 'transaction:fulfillment.failed':
            walletError.value = 'Transaction failed, please try again'
            walletError.value = 'Transaction failed, please try again'
            walletErrorName.value = 'Transaction failed, please try again'
            isPaying.value = false
            validateWalletError()
            break

          default:
            break
        }
      })

      break

    default:
      break
  }
}

/**
 * Queries
 **/

const {
  load: getTokenTransactionHash,
  loading: getTokenTransactionHashLoading,
} = useLazyQuery<GetTokenTransactionHash, GetTokenTransactionHashVariables>(GET_TOKEN_TRANSACTION_HASH)

/**
 * Mutations
 **/

const {
  mutate: recordTip,
  loading: recordTipLoading,
  onResult: recordTipOnResult,
  onError: recordTipOnError,
} = useMutation<RecordTip, RecordTipVariables>(RECORD_TIP)

const handleAccountActivity = (data: AccountActivity_accountActivity) => {
  if (data && props.isOpen) {
    const { __typename } = data

    // type narrowing to what's token-related only
    // won't accept array.includes
    if (__typename === 'TokenPurchaseConfirming'
      || __typename === 'TokenPurchasePending'
      || __typename === 'TokenPurchaseFailed'
      || __typename === 'TokenPurchaseSuccess') {
      if (__typename === 'TokenPurchaseSuccess') {
        const { collectionName, tokenId } = data
      }

      if (__typename === 'TokenPurchaseConfirming' && !showTransactionReceiptComponent.value) {
        const { transactionHash } = data

        hash.value = transactionHash as HexString
        showTransactionReceiptComponent.value = true
      }

      const { collectionId, tokenId } = data
      const existingItem = activity.value.find(item => item.collectionId === collectionId && item.tokenId === tokenId)

      if (existingItem) {
        activity.value[activity.value.indexOf(existingItem)] = data
      } else {
        activity.value.push(data)
      }
    }
  }
}

const handleTransactionSuccess = async (newReceipt, toAddress) => {
  showTransactionReceiptComponent.value = false

  if (tipArtistFlow.value) {
    const input =  {
      hash: newReceipt.transactionHash,
      depositorId: accountId.value,
      recipientId: toAddress,
      amount: supportAmount.value,
      value: weiPrepare.value,
      fundingSource: fundingSource.value,
    }

    recordTip({ input })
  } else if (!depositFlowActive.value) {
    if (!transactionSuccessful.value) { //means its already recorded using another flow
      await purchaseComplete(props.collection.id, tokenId.value, useLoginAccount.value?.id, toAddress, supportAmount.value, parseFloat(formatEther(mintTransaction.value)), newReceipt.transactionHash)
    }
  }

  if (!transactionSuccessful.value) {
    const { url } = getChainInfo(chainId.value)
    transactionUrl.value = `https:${url}/tx/${newReceipt.transactionHash}`

    emit('successful-mint')
    transactionSuccessful.value = true
  }

  transactionComplete.value = true
  waitingOnMutations.value = false

  if (!payingWithCrossmintActive.value) {
    isWaitingForTransaction.value = false
  }

  waitForBalance()
}

const handleTransactionFail = async () => {
  transactionUrl.value = ``

  showTransactionReceiptComponent.value = false
  transactionComplete.value = true
  transactionSuccessful.value = false

  addSnack({ message: 'Transaction failed, please try again', color: 'negative' })

  waitingOnMutations.value = false
}

const refresh = () => resetAll()

const resetAll = () => {
  if (reservedTokenId.value && !transactionSuccessful.value) {
    releaseToken(reservedTokenId.value)
  }

  needToConfirm.value = true
  isPaying.value = false
  transactionCrossmintSuccessful.value = false
  selectPriceFlowActive.value = true
  payingWithBalanceActive.value = false
  mintFlowActive.value = false
  payingWithCrossmintActive.value = false
  supportAmount.value = 0
  reservedTokenId.value = null
  transactionUrl.value = ''
  transactionComplete.value = false
  hash.value = undefined
  showTransactionReceiptComponent.value = false
  purchaseDetails.value = null
  transactionSuccessful.value = false
  isWaitingForTransaction.value = false
  waitingOnMutations.value = false
  totalFiatItemPrice.value = null
  totalGasFee.value = null
  currencyCrossmint.value = null
  isPaying.value = true
  payingWithDigitalWalletActive.value = false
  walletError.value = null
  walletErrorName.value = ''
  weiPrepare.value = 0
}

const close = () => {
  resetAll()

  emit('close')
}

const handleIframe = () => {
  // Create hidden iframe
  const iframe = document.createElement('iframe')
  iframe.style.display = 'none'
  iframe.src = shareUrl.value

  // Remove iframe after it loads
  iframe.onload = () => {
    document.body.removeChild(iframe)
  }
  // Add iframe to the document
  document.body.appendChild(iframe)
}

const shareUrl = computed(() => {
  const slug = props.collection.profiles[0].username
  return `https://${import.meta.env.VITE_BASE_DOMAIN_NAME}/${slug}/drop/${props.dropId}`
})

const momentOrDrop = computed(() => {
  if (props.dropType === DropType.Moment) {
    return 'moment'
  } else {
    return 'drop'
  }
})

const handleTwitterShare = () => {
  if (twitterUsername.value) {
    handleIframe()

    const twitterUrl = `https://twitter.com/share?text=Check%20out%20this%20new%20${momentOrDrop.value}%20on%20Remx!%20Only%20available%20for%2024%20hours.%0A%0A&url=${shareUrl.value}`

    window.open(twitterUrl, '_blank')
  } else {
    close()
    addSnack({ message: `Please connect your X account to your profile to share ${dropDetails.value.title}s on X.`, color: 'brand' })

    router.push({ name: 'profile-edit', params: { profileSlug: accountSlug.value }, hash: '#connected-apps' })
  }
}

const formattedDate = ref('')
const dateInterval = ref()
const endComplete = ref(false)

const startCountdown = (endDate: string) =>{
  const validate = () => {
    formattedDate.value = formatFutureDate(endDate, true)

    if (formattedDate.value === 'Done') {
      removeInterval()
      endComplete.value = true
    } else {
      endComplete.value = false
    }
  }

  validate()

  dateInterval.value = setInterval(() => {
    validate()
  }, 1000)
}

const removeInterval = () => {
  if (dateInterval.value) {
    clearInterval(dateInterval.value)
    endComplete.value = true
  }

  emit('close')
}

let intervalId

watch(tokenId, (newTokenId) => {
  if (newTokenId) {
    intervalId = setInterval(async () => {
      try {
        const result = await publicClient.readContract({
          address: contractId.value,
          abi: ownerOfAbi,
          functionName: 'ownerOf',
          args: [parseUnits(`${newTokenId}`, 0)],
        })

        // the transaction is complete, check if we got a transaction hash from
        // the wallet, crossmint or via the AccountActivity subscription.
        // if not, we can ask the backend to fetch the transaction hash for us.
        if (!hash.value) {
          const resultGetTransactionHash = await getTokenTransactionHash({ collectionId: props.collection.id, tokenId:newTokenId, address: mintToAddress.value })

          if (resultGetTransactionHash.data?.getTokenTransactionHash) {
            hash.value = resultGetTransactionHash.data.getTokenTransactionHash as HexString
            showTransactionReceiptComponent.value = true
            clearInterval(intervalId)
          }
        }
      } catch (error) {
        // Invalid token id exception when the token isn't minted yet, we can ignore
      }}, 2000)
  } else {
    clearInterval(intervalId)
  }
})

watch(() => [endDate.value,props.isOpen], ([newEndDate, newIsOpen]) => {
  if (newEndDate || newIsOpen) {
    if (dateInterval.value) {
      clearInterval(dateInterval.value)
    }

    if (newIsOpen) {
      startCountdown(newEndDate as string)
    }
  }
})

// save the connection type to local storage if the connector name changes
watch(() => activeConnector.value, (newConnector) => {
  if (newConnector && newConnector.name) {
    localStorage.setItem('remx-connectionType', newConnector.name)
  }
}, { immediate: true })

watch(accountActivityOnResult, (newAccountActivityOnResult) => {
  if (newAccountActivityOnResult) {
    handleAccountActivity(newAccountActivityOnResult)
  }
})

onUnmounted(() => {
  removeInterval()
  endComplete.value = true

  stop()

  intervalId && clearInterval(intervalId)
  intervalId = null
})
</script>
