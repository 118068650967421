export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  Cursor: { input: any; output: any; }
  JSON: { input: any; output: any; }
  JSONObject: { input: any; output: any; }
  JWTToken: { input: any; output: any; }
  LoginMessage: { input: any; output: any; }
  URL: { input: any; output: any; }
  bio_String_maxLength_256: { input: any; output: any; }
  email_String_format_email: { input: any; output: any; }
  instagram_String_maxLength_30: { input: any; output: any; }
  twitter_String_maxLength_15: { input: any; output: any; }
  username_String_minLength_3: { input: any; output: any; }
  website_String_maxLength_512: { input: any; output: any; }
};

export type Account = {
  __typename?: 'Account';
  banner?: Maybe<Scalars['URL']['output']>;
  bio?: Maybe<Scalars['String']['output']>;
  blocked?: Maybe<Scalars['Boolean']['output']>;
  collaborations: Array<Collaborator>;
  created: Scalars['Int']['output'];
  defaultWallet?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  genesis?: Maybe<Scalars['JSONObject']['output']>;
  icon?: Maybe<Scalars['URL']['output']>;
  id: Scalars['ID']['output'];
  notifications: AccountNotifications;
  phoneNumber?: Maybe<Scalars['String']['output']>;
  profile?: Maybe<Profile>;
  profileId?: Maybe<Scalars['String']['output']>;
  reputation?: Maybe<Reputation>;
  roles: Array<AccountRole>;
  showroom?: Maybe<Showroom>;
  showroomId?: Maybe<Scalars['ID']['output']>;
  slug: Scalars['String']['output'];
  social?: Maybe<SocialLinks>;
  tokens: Array<Token>;
  type?: Maybe<AccountTypes>;
  username?: Maybe<Scalars['String']['output']>;
  verified?: Maybe<Scalars['Boolean']['output']>;
  verifiedType: VerifiedType;
  wallet?: Maybe<WalletType>;
  wallets?: Maybe<Array<Wallet>>;
};

export type AccountActivity = JoinCommunity | LeaveCommunity | PendingMomentComplete | PendingMomentFailed | PendingMomentPending | PendingMomentProcessing | TokenPurchaseConfirming | TokenPurchaseFailed | TokenPurchasePending | TokenPurchaseSuccess | TokenRenderComplete | TokenTransfer;

export type AccountBenefit = {
  __typename?: 'AccountBenefit';
  benefit: Benefit;
  benefitId: Scalars['ID']['output'];
  collectionId: Scalars['ID']['output'];
  status: TokenBenefitStatusType;
};

export type AccountInput = {
  banner?: InputMaybe<Scalars['URL']['input']>;
  bio?: InputMaybe<Scalars['bio_String_maxLength_256']['input']>;
  defaultWallet?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['email_String_format_email']['input']>;
  icon?: InputMaybe<Scalars['URL']['input']>;
  id: Scalars['ID']['input'];
  notifications?: InputMaybe<AccountNotificationsInput>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  showroomId?: InputMaybe<Scalars['ID']['input']>;
  social?: InputMaybe<SocialLinksInput>;
  type?: InputMaybe<AccountTypes>;
  username?: InputMaybe<Scalars['username_String_minLength_3']['input']>;
};

export type AccountNotifications = {
  __typename?: 'AccountNotifications';
  claim?: Maybe<Scalars['Boolean']['output']>;
  collectableMomentsUpdateDismissed?: Maybe<Scalars['Boolean']['output']>;
  comment?: Maybe<Scalars['Boolean']['output']>;
  daily?: Maybe<Scalars['Boolean']['output']>;
  emailMigrationMessage?: Maybe<Scalars['Boolean']['output']>;
  enabled: Scalars['Boolean']['output'];
  farcaster?: Maybe<FarcasterNotifications>;
  join?: Maybe<Scalars['Boolean']['output']>;
  loginMigrationMessage?: Maybe<Scalars['Boolean']['output']>;
  mentions?: Maybe<Scalars['Boolean']['output']>;
  reaction?: Maybe<Scalars['Boolean']['output']>;
  reward?: Maybe<Scalars['Boolean']['output']>;
  settingsMigrationMessage?: Maybe<Scalars['Boolean']['output']>;
  tip?: Maybe<Scalars['Boolean']['output']>;
  xProfileDismissed?: Maybe<Scalars['Boolean']['output']>;
};

export type AccountNotificationsInput = {
  claim?: InputMaybe<Scalars['Boolean']['input']>;
  collectableMomentsUpdateDismissed?: InputMaybe<Scalars['Boolean']['input']>;
  comment?: InputMaybe<Scalars['Boolean']['input']>;
  daily?: InputMaybe<Scalars['Boolean']['input']>;
  emailMigrationMessage?: InputMaybe<Scalars['Boolean']['input']>;
  enabled?: InputMaybe<Scalars['Boolean']['input']>;
  farcaster?: InputMaybe<FarcasterNotificationsInput>;
  join?: InputMaybe<Scalars['Boolean']['input']>;
  loginMigrationMessage?: InputMaybe<Scalars['Boolean']['input']>;
  mentions?: InputMaybe<Scalars['Boolean']['input']>;
  reaction?: InputMaybe<Scalars['Boolean']['input']>;
  reward?: InputMaybe<Scalars['Boolean']['input']>;
  settingsMigrationMessage?: InputMaybe<Scalars['Boolean']['input']>;
  tip?: InputMaybe<Scalars['Boolean']['input']>;
  xProfileDismissed?: InputMaybe<Scalars['Boolean']['input']>;
};

export enum AccountRole {
  Admin = 'admin',
  Collector = 'collector',
  Creator = 'creator',
  Moderator = 'moderator',
  Sponsor = 'sponsor',
  Staff = 'staff',
  Verified = 'verified'
}

export enum AccountTypes {
  Admin = 'admin',
  Artist = 'artist',
  Collector = 'collector'
}

export type ActivateSponsorshipInput = {
  id: Scalars['ID']['input'];
  transactionHash: Scalars['String']['input'];
};

export type AddCollaboratorInput = {
  accountId: Scalars['ID']['input'];
  collectionId: Scalars['ID']['input'];
  shares: Scalars['Int']['input'];
};

export type Address = {
  __typename?: 'Address';
  accountId: Scalars['ID']['output'];
  address: Scalars['ID']['output'];
  connectionType?: Maybe<Scalars['String']['output']>;
  label?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
};

export type AlchemyConfig = {
  __typename?: 'AlchemyConfig';
  apiKey: Scalars['String']['output'];
  url: Scalars['String']['output'];
};

export enum AllowPostsBy {
  Collaborators = 'collaborators',
  Members = 'members',
  Owners = 'owners'
}

export type ApproveCollectionInput = {
  id: Scalars['ID']['input'];
};

export type ArchiveCollectionInput = {
  id: Scalars['ID']['input'];
};

export type ArchiveShopifyProductInput = {
  collectionId: Scalars['ID']['input'];
  productId: Scalars['String']['input'];
};

export type Auction = {
  __typename?: 'Auction';
  airdropSize: Scalars['Int']['output'];
  collectionId: Scalars['ID']['output'];
  comingSoonDate: Scalars['String']['output'];
  countdownDate: Scalars['String']['output'];
  currency: Currency;
  endDate?: Maybe<Scalars['String']['output']>;
  floorPrice: Scalars['Float']['output'];
  launchType: LaunchDateType;
  presaleDate?: Maybe<Scalars['String']['output']>;
  privatesaleDate?: Maybe<Scalars['String']['output']>;
  privatesaleSize: Scalars['Int']['output'];
  saleDate: Scalars['String']['output'];
};

export type AvatarConfiguration = {
  __typename?: 'AvatarConfiguration';
  avatarFile: Scalars['String']['output'];
  avatarFileUrl: Scalars['String']['output'];
  avatarIcon: Scalars['String']['output'];
  avatarIconUrl: Scalars['String']['output'];
  compatibility: Array<ModelCompatibility>;
};

export type Balance = {
  __typename?: 'Balance';
  balance: Scalars['Float']['output'];
  chainId: Scalars['Int']['output'];
};

export type Benefit = {
  __typename?: 'Benefit';
  categories?: Maybe<Array<BenefitCategory>>;
  collection?: Maybe<Collection>;
  description?: Maybe<Scalars['String']['output']>;
  dropType?: Maybe<DropType>;
  endDate?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  listed: Scalars['Boolean']['output'];
  metadata?: Maybe<Scalars['JSON']['output']>;
  reaction?: Maybe<ReactionType>;
  roles: Array<BenefitRoleType>;
  scope: BenefitScopeType;
  sponsor?: Maybe<Sponsor>;
  sponsorship?: Maybe<Sponsorship>;
  startDate?: Maybe<Scalars['String']['output']>;
  stats: BenefitStats;
  title?: Maybe<Scalars['String']['output']>;
  tokenSupply?: Maybe<TokenSupply>;
  type?: Maybe<BenefitType>;
  usage: BenefitUsageType;
};

export enum BenefitCategory {
  Ai = 'ai',
  Bts = 'bts',
  Design = 'design',
  Generative = 'generative',
  Hybrid = 'hybrid',
  Illustration = 'illustration',
  Music = 'music',
  Other = 'other',
  Painting = 'painting',
  Photography = 'photography',
  Sketch = 'sketch',
  Threed = 'threed',
  Vector = 'vector',
  Video = 'video',
  Wip = 'wip'
}

export enum BenefitRoleType {
  Artist = 'artist',
  Genesis = 'genesis',
  Holder = 'holder'
}

export enum BenefitScopeType {
  Collection = 'collection',
  Community = 'community',
  Token = 'token'
}

export type BenefitStats = {
  __typename?: 'BenefitStats';
  comments: Scalars['Int']['output'];
  impressions: Scalars['Int']['output'];
  reactions: Scalars['Int']['output'];
  shares: Scalars['Int']['output'];
  views: Scalars['Int']['output'];
};

export enum BenefitType {
  Airdrop = 'airdrop',
  Canvasprint = 'canvasprint',
  Collection = 'collection',
  Download = 'download',
  File = 'file',
  Giftcode = 'giftcode',
  Link = 'link',
  Metalprint = 'metalprint',
  Ownership = 'ownership',
  Privatesale = 'privatesale',
  Shoplink = 'shoplink',
  Sponsorship = 'sponsorship',
  Text = 'text'
}

export enum BenefitUsageType {
  Limited = 'limited',
  Unlimited = 'unlimited'
}

export type Chain = {
  __typename?: 'Chain';
  alchemy: AlchemyConfig;
  chainId: Scalars['Int']['output'];
  crossmintConfig: CrossmintConfig;
};

export type ChallengeEmailAddressInput = {
  email: Scalars['String']['input'];
};

export type ChallengeWalletInput = {
  address: Scalars['ID']['input'];
};

export type ChallengeWithdrawEarningsInput = {
  address: Scalars['ID']['input'];
};

export type ClaimEarnings = {
  __typename?: 'ClaimEarnings';
  accountId: Scalars['ID']['output'];
  chainId: Scalars['Int']['output'];
  claims?: Maybe<Array<ClaimEarningsResult>>;
};

export type ClaimEarningsResult = {
  __typename?: 'ClaimEarningsResult';
  collectionId: Scalars['String']['output'];
  contractId: Scalars['String']['output'];
  creatorIcon?: Maybe<Scalars['String']['output']>;
  creatorSlug: Scalars['String']['output'];
  creatorUsername: Scalars['String']['output'];
  date: Scalars['String']['output'];
  dropIcon: Scalars['String']['output'];
  dropId: Scalars['String']['output'];
  dropStatus: Scalars['String']['output'];
  dropTitle: Scalars['String']['output'];
  payment?: Maybe<ClaimPayment>;
  revealed: Scalars['Boolean']['output'];
  sponsorIcon: Scalars['String']['output'];
  sponsorSlug: Scalars['String']['output'];
  sponsorUsername: Scalars['String']['output'];
  tokenId: Scalars['Int']['output'];
};

export type ClaimPayment = {
  __typename?: 'ClaimPayment';
  amount: Scalars['Float']['output'];
  date: Scalars['Int']['output'];
};

export type ClaimRevealedInput = {
  collectionId: Scalars['ID']['input'];
  tokenId: Scalars['Int']['input'];
};

export type ClaimTokenInput = {
  token: Scalars['String']['input'];
};

export type ClaimTokenParamsResult = {
  __typename?: 'ClaimTokenParamsResult';
  contractId: Scalars['String']['output'];
  expiryBlock: Scalars['Int']['output'];
  metaTransaction: MetaTransaction;
  price: Scalars['String']['output'];
  signature: Scalars['String']['output'];
  tokenId: Scalars['Int']['output'];
};

export type ClaimTokenReturn = ClaimTokenParamsResult | TransactionResult;

export type Collaborator = {
  __typename?: 'Collaborator';
  account: Account;
  accountId: Scalars['ID']['output'];
  collection: Collection;
  collectionId: Scalars['ID']['output'];
  id: Scalars['ID']['output'];
  profile: Profile;
  shares?: Maybe<Scalars['Int']['output']>;
};

export type CollaboratorInput = {
  accountId: Scalars['ID']['input'];
  shares: Scalars['Int']['input'];
};

export type Collection = {
  __typename?: 'Collection';
  accountId?: Maybe<Scalars['ID']['output']>;
  archived?: Maybe<Scalars['Boolean']['output']>;
  auction?: Maybe<Auction>;
  banner?: Maybe<Scalars['URL']['output']>;
  benefits: Array<CollectionBenefit>;
  chainId?: Maybe<Scalars['Int']['output']>;
  collaborators: Array<Collaborator>;
  communityIds?: Maybe<Array<Scalars['ID']['output']>>;
  contractId?: Maybe<Scalars['String']['output']>;
  contractName: Scalars['String']['output'];
  crossmintConfig?: Maybe<CrossmintConfig>;
  description?: Maybe<Scalars['String']['output']>;
  drop?: Maybe<Benefit>;
  icon?: Maybe<Scalars['URL']['output']>;
  id: Scalars['ID']['output'];
  listed?: Maybe<Scalars['Boolean']['output']>;
  metadata?: Maybe<Scalars['JSON']['output']>;
  name: Scalars['String']['output'];
  params?: Maybe<Scalars['String']['output']>;
  premints?: Maybe<Array<PremintList>>;
  profiles?: Maybe<Array<Profile>>;
  requestedChanges?: Maybe<Scalars['String']['output']>;
  reservedCount?: Maybe<Scalars['Int']['output']>;
  royalties?: Maybe<Scalars['Int']['output']>;
  size: Scalars['Int']['output'];
  slug: Scalars['String']['output'];
  soldCount?: Maybe<Scalars['Int']['output']>;
  status: CollectionStatus;
  symbol?: Maybe<Scalars['String']['output']>;
  tokenGates?: Maybe<Array<TokenGate>>;
  tokenLimit: TokenLimit;
  tokens: PaginatedTokensResult;
  totalRevenue?: Maybe<Scalars['Float']['output']>;
  totalTips?: Maybe<Scalars['Int']['output']>;
  type: CollectionTypes;
};


export type CollectionTokensArgs = {
  cursor?: InputMaybe<Scalars['Cursor']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
};

export type CollectionBenefit = {
  __typename?: 'CollectionBenefit';
  benefit: Benefit;
  benefitId: Scalars['ID']['output'];
  collection: Collection;
  collectionId: Scalars['ID']['output'];
  sortOrder: Scalars['Int']['output'];
};

export type CollectionEarnings = {
  __typename?: 'CollectionEarnings';
  accountId: Scalars['ID']['output'];
  chainId: Scalars['Int']['output'];
  collections: Array<CollectionEarningsDetail>;
};

export type CollectionEarningsDetail = {
  __typename?: 'CollectionEarningsDetail';
  benefitId?: Maybe<Scalars['ID']['output']>;
  collection?: Maybe<Collection>;
  collectionId: Scalars['ID']['output'];
  contractId: Scalars['ID']['output'];
  earnings: Scalars['Float']['output'];
};

export enum CollectionStatus {
  Approved = 'approved',
  Comingsoon = 'comingsoon',
  Complete = 'complete',
  Countdown = 'countdown',
  Deploying = 'deploying',
  Hidden = 'hidden',
  Presale = 'presale',
  Privatesale = 'privatesale',
  Published = 'published',
  Rejected = 'rejected',
  Review = 'review',
  Sale = 'sale',
  Unpublished = 'unpublished'
}

export enum CollectionTypes {
  Remx = 'REMX',
  Single = 'Single',
  Standard = 'Standard'
}

export type Comment = {
  __typename?: 'Comment';
  content: Scalars['String']['output'];
  created: Scalars['Int']['output'];
  id: Scalars['ID']['output'];
  locked: Scalars['Boolean']['output'];
  moderation: ModerationStatus;
  profile: Profile;
  reaction?: Maybe<ReactionType>;
  relationId: Scalars['ID']['output'];
  relationType?: Maybe<RelationType>;
  stats: CommentStatistics;
  status: CommentStatus;
  updated: Scalars['Int']['output'];
};

export type CommentStatistics = {
  __typename?: 'CommentStatistics';
  comments: Scalars['Int']['output'];
  reactions: Scalars['Int']['output'];
};

export enum CommentStatus {
  Archived = 'archived',
  Draft = 'draft',
  Published = 'published'
}

export type Community = {
  __typename?: 'Community';
  account: Account;
  accountId: Scalars['ID']['output'];
  banner: Scalars['URL']['output'];
  benefits?: Maybe<Array<CommunityBenefit>>;
  created: Scalars['Int']['output'];
  description: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  memberCount: Scalars['Int']['output'];
  membershipCard: Scalars['URL']['output'];
  name: Scalars['String']['output'];
  plan: Plan;
  planId: Scalars['ID']['output'];
  published: Scalars['Boolean']['output'];
  settings: CommunitySettings;
  slug: Scalars['String']['output'];
  social?: Maybe<SocialLinks>;
  status: CommunityStatus;
  stripeSubscriptionId?: Maybe<Scalars['ID']['output']>;
  subscriptionStatus?: Maybe<SubscriptionStatus>;
};

export type CommunityBenefit = {
  __typename?: 'CommunityBenefit';
  benefit: Benefit;
  benefitId: Scalars['ID']['output'];
  community: Community;
  communityId: Scalars['ID']['output'];
  created: Scalars['Int']['output'];
};

export type CommunityMember = {
  __typename?: 'CommunityMember';
  account: Account;
  accountId: Scalars['ID']['output'];
  community: Community;
  communityId: Scalars['ID']['output'];
  joined: Scalars['Int']['output'];
  notifications: CommunityMemberNotifications;
  role: CommunityMemberRole;
};

export type CommunityMemberNotifications = {
  __typename?: 'CommunityMemberNotifications';
  digest: DigestType;
};

export type CommunityMemberNotificationsInput = {
  digest?: InputMaybe<DigestType>;
};

export enum CommunityMemberRole {
  Collaborator = 'collaborator',
  Member = 'member',
  Owner = 'owner'
}

export type CommunitySettings = {
  __typename?: 'CommunitySettings';
  allowPostsBy: AllowPostsBy;
  enableCommunityHub: Scalars['Boolean']['output'];
  sendNewPostEmails: Scalars['Boolean']['output'];
};

export type CommunitySettingsInput = {
  allowPostsBy?: InputMaybe<AllowPostsBy>;
  enableCommunityHub?: InputMaybe<Scalars['Boolean']['input']>;
  sendNewPostEmails?: InputMaybe<Scalars['Boolean']['input']>;
};

export enum CommunityStatus {
  Active = 'active',
  Inactive = 'inactive',
  Suspended = 'suspended'
}

export type CreateAccountInput = {
  address?: InputMaybe<Scalars['ID']['input']>;
  connectionType?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['email_String_format_email']['input']>;
  farcasterUsername?: InputMaybe<Scalars['String']['input']>;
  fid?: InputMaybe<Scalars['String']['input']>;
};

export type CreateAddressInput = {
  accountId: Scalars['ID']['input'];
  address: Scalars['ID']['input'];
};

export type CreateAuctionInput = {
  airdropSize: Scalars['Int']['input'];
  collectionId: Scalars['ID']['input'];
  comingSoonDate: Scalars['String']['input'];
  countdownDate: Scalars['String']['input'];
  currency: Currency;
  endDate?: InputMaybe<Scalars['String']['input']>;
  floorPrice: Scalars['Float']['input'];
  presaleDate?: InputMaybe<Scalars['String']['input']>;
  privatesaleDate?: InputMaybe<Scalars['String']['input']>;
  privatesaleSize: Scalars['Int']['input'];
  saleDate: Scalars['String']['input'];
};

export type CreateBenefitInput = {
  categories?: InputMaybe<Array<BenefitCategory>>;
  description: Scalars['String']['input'];
  dropType?: InputMaybe<DropType>;
  endDate?: InputMaybe<Scalars['String']['input']>;
  metadata?: InputMaybe<Scalars['JSON']['input']>;
  roles: Array<BenefitRoleType>;
  scope: BenefitScopeType;
  startDate?: InputMaybe<Scalars['String']['input']>;
  title: Scalars['String']['input'];
  type: BenefitType;
  usage: BenefitUsageType;
};

export type CreateCollectionBenefitInput = {
  collectionId: Scalars['ID']['input'];
  description: Scalars['String']['input'];
  endDate?: InputMaybe<Scalars['String']['input']>;
  metadata?: InputMaybe<Scalars['JSON']['input']>;
  roles: Array<BenefitRoleType>;
  scope: BenefitScopeType;
  sortOrder?: InputMaybe<Scalars['Int']['input']>;
  startDate?: InputMaybe<Scalars['String']['input']>;
  title: Scalars['String']['input'];
  type: BenefitType;
  usage: BenefitUsageType;
};

export type CreateCollectionInput = {
  accountId: Scalars['ID']['input'];
  banner?: InputMaybe<Scalars['URL']['input']>;
  contractName: Scalars['String']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  icon?: InputMaybe<Scalars['URL']['input']>;
  metadata?: InputMaybe<Scalars['JSON']['input']>;
  name: Scalars['String']['input'];
  params?: InputMaybe<Scalars['String']['input']>;
  size: Scalars['Int']['input'];
  tokenLimit?: InputMaybe<TokenLimit>;
  type: CollectionTypes;
};

export type CreateCommentInput = {
  accountId: Scalars['ID']['input'];
  communityId: Scalars['ID']['input'];
  content?: InputMaybe<Scalars['String']['input']>;
  relationId: Scalars['ID']['input'];
  relationType: RelationType;
};

export type CreateCommunityBenefitInput = {
  communityId: Scalars['ID']['input'];
  description: Scalars['String']['input'];
  endDate?: InputMaybe<Scalars['String']['input']>;
  metadata?: InputMaybe<Scalars['JSON']['input']>;
  roles: Array<BenefitRoleType>;
  scope: BenefitScopeType;
  startDate?: InputMaybe<Scalars['String']['input']>;
  title: Scalars['String']['input'];
  type: BenefitType;
  usage: BenefitUsageType;
};

export type CreateCommunityInput = {
  accountId: Scalars['ID']['input'];
  banner: Scalars['URL']['input'];
  description: Scalars['String']['input'];
  membershipCard: Scalars['URL']['input'];
  name: Scalars['String']['input'];
  slug: Scalars['String']['input'];
  social?: InputMaybe<SocialLinksInput>;
};

export type CreateCommunityMemberInput = {
  accountId: Scalars['ID']['input'];
  communityId: Scalars['ID']['input'];
};

export type CreateCustomWearableInput = {
  accountId: Scalars['ID']['input'];
  color: Scalars['String']['input'];
  icon: Scalars['String']['input'];
  modelId: Scalars['ID']['input'];
  texture: Scalars['String']['input'];
};

export type CreateDefaultShowroomInput = {
  accountId: Scalars['ID']['input'];
  avatar: ShowroomModelInput;
};

export type CreateModelInput = {
  elements: Scalars['JSONObject']['input'];
  file: Scalars['String']['input'];
  icon: Scalars['String']['input'];
  id: Scalars['ID']['input'];
  lights: Scalars['String']['input'];
  listed: Scalars['Boolean']['input'];
  metals: Scalars['String']['input'];
  name: Scalars['String']['input'];
  orthoOffset: Scalars['Float']['input'];
};

export type CreatePendingMomentInput = {
  accountId: Scalars['ID']['input'];
  benefitMetadata: Scalars['JSON']['input'];
  categories?: InputMaybe<Array<BenefitCategory>>;
  chainId: Scalars['Int']['input'];
  collectionMetadata: Scalars['JSON']['input'];
  description: Scalars['String']['input'];
  dropType: DropType;
  icon: Scalars['URL']['input'];
  name: Scalars['String']['input'];
  price?: InputMaybe<Scalars['Float']['input']>;
};

export type CreatePostInput = {
  accountId: Scalars['ID']['input'];
  communityId: Scalars['ID']['input'];
  content: Scalars['String']['input'];
  media: Array<MediaInput>;
};

export type CreateRewardInput = {
  benefitMetadata: Scalars['JSON']['input'];
  categories?: InputMaybe<Array<BenefitCategory>>;
  chainId: Scalars['Int']['input'];
  collectionMetadata: Scalars['JSON']['input'];
  communityId: Scalars['ID']['input'];
  description: Scalars['String']['input'];
  dropType: DropType;
  icon: Scalars['URL']['input'];
  name: Scalars['String']['input'];
  price?: InputMaybe<Scalars['Float']['input']>;
};

export type CreateShopifyProductInput = {
  collectionId: Scalars['ID']['input'];
  description: Scalars['String']['input'];
  images: Array<Scalars['String']['input']>;
  isPublished: Scalars['Boolean']['input'];
  price: Scalars['String']['input'];
  shippingProfile: Scalars['String']['input'];
  title: Scalars['String']['input'];
};

export type CreateSponsorshipInput = {
  amount: Scalars['Float']['input'];
  benefitId: Scalars['ID']['input'];
  duration: Scalars['Int']['input'];
  endDate: Scalars['Int']['input'];
  perk: SponsorshipPerkInput;
  revenueShare: SponsorshipRevenueShareInput;
  sponsorId: Scalars['ID']['input'];
};

export type CrossmintConfig = {
  __typename?: 'CrossmintConfig';
  clientId: Scalars['String']['output'];
  projectId: Scalars['String']['output'];
};

export enum Currency {
  Eth = 'ETH',
  Matic = 'MATIC',
  Usd = 'USD'
}

export type CustomWearable = {
  __typename?: 'CustomWearable';
  accountId: Scalars['ID']['output'];
  color: Scalars['String']['output'];
  icon: Scalars['String']['output'];
  iconUrl: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  model: Model;
  modelId: Scalars['ID']['output'];
  texture: Scalars['String']['output'];
  textureUrl: Scalars['String']['output'];
};

export type CustomWearableModel = {
  __typename?: 'CustomWearableModel';
  customWearable: CustomWearable;
  customWearableId: Scalars['ID']['output'];
  model?: Maybe<Model>;
  modelId: Scalars['ID']['output'];
};

export enum DataGranularity {
  Day = 'day',
  Hour = 'hour',
  Month = 'month',
  Week = 'week'
}

export type DataLabel = {
  __typename?: 'DataLabel';
  icon?: Maybe<Scalars['URL']['output']>;
  isFollowing?: Maybe<Scalars['Boolean']['output']>;
  link?: Maybe<Link>;
  subtitle?: Maybe<Scalars['String']['output']>;
  title: Scalars['String']['output'];
  type?: Maybe<LabelType>;
};

export type DataSet = {
  __typename?: 'DataSet';
  data: Array<Maybe<Array<Maybe<Scalars['Int']['output']>>>>;
  labels: Array<DataLabel>;
  titles: Array<Scalars['String']['output']>;
};

export type DefaultModel = {
  __typename?: 'DefaultModel';
  model?: Maybe<Model>;
  modelId: Scalars['ID']['output'];
};

export type DeployCollectionInput = {
  id: Scalars['ID']['input'];
};

export type Deposit = {
  __typename?: 'Deposit';
  amount: Scalars['Float']['output'];
  created: Scalars['Int']['output'];
  depositor: Profile;
  depositorId: Scalars['ID']['output'];
  hash: Scalars['ID']['output'];
  recipient: Profile;
  recipientId: Scalars['ID']['output'];
  thanked: Scalars['Boolean']['output'];
  value: Scalars['Float']['output'];
};

export type DepositThankYouInput = {
  hash: Scalars['ID']['input'];
};

export enum DigestType {
  Daily = 'daily',
  None = 'none'
}

export type DomainDefinition = {
  __typename?: 'DomainDefinition';
  chainId: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  verifyingContract: Scalars['String']['output'];
  version: Scalars['String']['output'];
};

export type DropAnalytics = {
  __typename?: 'DropAnalytics';
  claims: SummaryData;
  comments: SummaryData;
  emails: SummaryData;
  impressions: SummaryData;
  minted: SummaryData;
  reactions: SummaryData;
  tokens: DataSet;
  topCollectors: DataSet;
};

export type DropLink = {
  __typename?: 'DropLink';
  dropId: Scalars['ID']['output'];
  profileId: Scalars['ID']['output'];
};

export enum DropType {
  Moment = 'moment',
  Reward = 'reward'
}

export type EarningsSummary = {
  __typename?: 'EarningsSummary';
  accountId: Scalars['ID']['output'];
  balance?: Maybe<Scalars['Float']['output']>;
  chainId: Scalars['Int']['output'];
  totalEarnings?: Maybe<Scalars['Float']['output']>;
  totalTokensSold: Scalars['Int']['output'];
};

export type EarningsWithdrawal = {
  __typename?: 'EarningsWithdrawal';
  accountId: Scalars['ID']['output'];
  chainId: Scalars['Int']['output'];
  withdrawals?: Maybe<Array<WithdrawalResult>>;
};

export type EndSaleInput = {
  id: Scalars['ID']['input'];
};

export type EquipCustomWearableInput = {
  accountId: Scalars['ID']['input'];
  id: Scalars['ID']['input'];
};

export type FarcasterNotifications = {
  __typename?: 'FarcasterNotifications';
  addToAccountDismissed?: Maybe<Scalars['Boolean']['output']>;
  shareProfileDismissed?: Maybe<Scalars['Boolean']['output']>;
};

export type FarcasterNotificationsInput = {
  addToAccountDismissed?: InputMaybe<Scalars['Boolean']['input']>;
  shareProfileDismissed?: InputMaybe<Scalars['Boolean']['input']>;
};

export type FeedDrop = {
  __typename?: 'FeedDrop';
  created: Scalars['Int']['output'];
  drop: CommunityBenefit;
  id: Scalars['ID']['output'];
  profile: Profile;
};

export type FeedInput = {
  cursor?: InputMaybe<Scalars['Cursor']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
};

export type FeedItem = FeedDrop | FeedUpdate;

export type FeedResult = {
  __typename?: 'FeedResult';
  cursor?: Maybe<Scalars['Cursor']['output']>;
  items: Array<FeedItem>;
};

export type FeedUpdate = {
  __typename?: 'FeedUpdate';
  content: Scalars['String']['output'];
  created: Scalars['Int']['output'];
  id: Scalars['ID']['output'];
  media: Array<Media>;
  profile: Profile;
  reaction?: Maybe<ReactionType>;
  stats?: Maybe<PostStats>;
  updated: Scalars['Int']['output'];
};

export enum FundingSource {
  Balance = 'balance',
  Crossmint = 'crossmint',
  Wallet = 'wallet'
}

export type GetBalancesInput = {
  chainId?: InputMaybe<Scalars['Int']['input']>;
  id: Scalars['ID']['input'];
};

export type GetBalancesResult = {
  __typename?: 'GetBalancesResult';
  balances: Array<Balance>;
};

export type GetCollaboratorsFilterInput = {
  accountId?: InputMaybe<Scalars['ID']['input']>;
  collectionId?: InputMaybe<Scalars['ID']['input']>;
};

export type GetCollaboratorsInput = {
  filter: GetCollaboratorsFilterInput;
};

export type GetCollectionsFilter = {
  accountId?: InputMaybe<Scalars['ID']['input']>;
  archived?: InputMaybe<Scalars['Boolean']['input']>;
  listed?: InputMaybe<Scalars['Boolean']['input']>;
  statuses?: InputMaybe<Array<InputMaybe<CollectionStatus>>>;
};

export type GetCollectionsInput = {
  cursor?: InputMaybe<Scalars['Cursor']['input']>;
  filter?: InputMaybe<GetCollectionsFilter>;
  limit?: InputMaybe<Scalars['Int']['input']>;
};

export type GetCollectorListInput = {
  benefitId?: InputMaybe<Scalars['ID']['input']>;
  id: Scalars['ID']['input'];
};

export type GetCollectorsInput = {
  cursor?: InputMaybe<Scalars['Cursor']['input']>;
  id: Scalars['ID']['input'];
  limit?: InputMaybe<Scalars['Int']['input']>;
};

export type GetCommentsInput = {
  communityId: Scalars['ID']['input'];
  cursor?: InputMaybe<Scalars['Cursor']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  relationId: Scalars['ID']['input'];
};

export type GetCommunitiesByAccountInput = {
  accountId: Scalars['ID']['input'];
  cursor?: InputMaybe<Scalars['Cursor']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
};

export type GetCommunitiesInput = {
  cursor?: InputMaybe<Scalars['Cursor']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
};

export type GetCommunityMembersInput = {
  communityId: Scalars['ID']['input'];
  cursor?: InputMaybe<Scalars['Cursor']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
};

export type GetCustomWearablesFilter = {
  accountId?: InputMaybe<Scalars['ID']['input']>;
};

export type GetCustomWearablesInput = {
  cursor?: InputMaybe<Scalars['Cursor']['input']>;
  filter?: InputMaybe<GetCustomWearablesFilter>;
  limit?: InputMaybe<Scalars['Int']['input']>;
};

export type GetDataInput = {
  accountId: Scalars['ID']['input'];
  endDate?: InputMaybe<Scalars['Int']['input']>;
  granularity?: InputMaybe<DataGranularity>;
  startDate?: InputMaybe<Scalars['Int']['input']>;
};

export type GetDepositsInput = {
  cursor?: InputMaybe<Scalars['Cursor']['input']>;
  id: Scalars['ID']['input'];
  limit?: InputMaybe<Scalars['Int']['input']>;
};

export type GetDropAnalyticsInput = {
  benefitId: Scalars['ID']['input'];
  communityId: Scalars['ID']['input'];
  timezone?: InputMaybe<Scalars['String']['input']>;
};

export type GetExchangePricesResult = {
  __typename?: 'GetExchangePricesResult';
  eth?: Maybe<Scalars['String']['output']>;
  matic?: Maybe<Scalars['String']['output']>;
};

export type GetFollowersInput = {
  cursor?: InputMaybe<Scalars['Cursor']['input']>;
  id: Scalars['ID']['input'];
  limit?: InputMaybe<Scalars['Int']['input']>;
};

export type GetFollowingInput = {
  cursor?: InputMaybe<Scalars['Cursor']['input']>;
  id: Scalars['ID']['input'];
  limit?: InputMaybe<Scalars['Int']['input']>;
};

export type GetLatestDropsInput = {
  limit: Scalars['Int']['input'];
};

export type GetModelsFilterInput = {
  free?: InputMaybe<Scalars['Boolean']['input']>;
  listed?: InputMaybe<Scalars['Boolean']['input']>;
  modelCategories?: InputMaybe<Array<InputMaybe<ModelCategoryType>>>;
};

export type GetModelsInput = {
  cursor?: InputMaybe<Scalars['Cursor']['input']>;
  filter?: InputMaybe<GetModelsFilterInput>;
  limit?: InputMaybe<Scalars['Int']['input']>;
};

export type GetMomentsInput = {
  cursor?: InputMaybe<Scalars['Cursor']['input']>;
  filter?: InputMaybe<Scalars['String']['input']>;
  lastMoment?: InputMaybe<Scalars['ID']['input']>;
  limit: Scalars['Int']['input'];
};

export type GetMomentsResult = {
  __typename?: 'GetMomentsResult';
  cursor?: Maybe<Scalars['Cursor']['output']>;
  moments: Array<Reward>;
};

export type GetMomentsSinceInput = {
  filter?: InputMaybe<Scalars['String']['input']>;
  lastMoment: Scalars['ID']['input'];
};

export type GetNewMomentCountInput = {
  filter?: InputMaybe<Scalars['String']['input']>;
  lastMoment: Scalars['ID']['input'];
  limit?: InputMaybe<Scalars['Int']['input']>;
};

export type GetNextDesignInput = {
  collectionId: Scalars['ID']['input'];
  state?: InputMaybe<Scalars['String']['input']>;
};

export type GetNextDesignReturn = {
  __typename?: 'GetNextDesignReturn';
  designCode: Scalars['String']['output'];
  state: Scalars['String']['output'];
};

export type GetNftAssetsByAddressInput = {
  address?: InputMaybe<Scalars['ID']['input']>;
  chainId: Scalars['Int']['input'];
  cursor?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
};

export type GetPendingMomentsInput = {
  accountId: Scalars['ID']['input'];
};

export type GetPostsFilterInput = {
  communityId: Scalars['ID']['input'];
  status?: InputMaybe<PostStatus>;
};

export type GetPostsInput = {
  cursor?: InputMaybe<Scalars['Cursor']['input']>;
  filter: GetPostsFilterInput;
  limit?: InputMaybe<Scalars['Int']['input']>;
};

export type GetProfileDataInput = {
  accountId: Scalars['ID']['input'];
  profileId: Scalars['ID']['input'];
};

export type GetProfileDropsInput = {
  cursor?: InputMaybe<Scalars['Cursor']['input']>;
  id: Scalars['ID']['input'];
  limit?: InputMaybe<Scalars['Int']['input']>;
};

export type GetProfileMomentsInput = {
  cursor?: InputMaybe<Scalars['Cursor']['input']>;
  id: Scalars['ID']['input'];
  limit?: InputMaybe<Scalars['Int']['input']>;
};

export type GetProfileTokensInput = {
  cursor?: InputMaybe<Scalars['Cursor']['input']>;
  id: Scalars['ID']['input'];
  limit?: InputMaybe<Scalars['Int']['input']>;
};

export type GetRewardCollectorsInput = {
  benefitId: Scalars['ID']['input'];
  cursor?: InputMaybe<Scalars['Cursor']['input']>;
  limit: Scalars['Int']['input'];
};

export type GetShowroomsInput = {
  cursor?: InputMaybe<Scalars['Cursor']['input']>;
  filter?: InputMaybe<GetShowroomsInputFilter>;
  limit?: InputMaybe<Scalars['Int']['input']>;
};

export type GetShowroomsInputFilter = {
  accountId: Scalars['ID']['input'];
};

export type GetTipsInput = {
  accountId: Scalars['ID']['input'];
  cursor?: InputMaybe<Scalars['Cursor']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
};

export type GetTokensByAccountInput = {
  accountId: Scalars['ID']['input'];
  address?: InputMaybe<Scalars['ID']['input']>;
  cursor?: InputMaybe<Scalars['Cursor']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
};

export type GetTokensFilterInput = {
  accountId?: InputMaybe<Scalars['ID']['input']>;
  collectionId?: InputMaybe<Scalars['ID']['input']>;
  modelCategories?: InputMaybe<Array<ModelCategoryType>>;
};

export type GetTokensInput = {
  cursor?: InputMaybe<Scalars['Cursor']['input']>;
  filter?: InputMaybe<GetTokensFilterInput>;
  limit?: InputMaybe<Scalars['Int']['input']>;
};

export type GetTopCollectorsInput = {
  limit: Scalars['Int']['input'];
};

export type GetTopTippedMomentsInput = {
  limit: Scalars['Int']['input'];
};

export type GetTopTippersInput = {
  limit: Scalars['Int']['input'];
};

export type GetTopTippingStreakInput = {
  limit: Scalars['Int']['input'];
};

export type GetTopTippingStreakRankInput = {
  slug: Scalars['String']['input'];
};

export type GetTopTipsGivenInput = {
  limit: Scalars['Int']['input'];
};

export type GetTopTipsGivenRankInput = {
  slug: Scalars['String']['input'];
};

export type GetTopTipsReceivedInput = {
  limit: Scalars['Int']['input'];
};

export type GetTopTipsReceivedRankInput = {
  slug: Scalars['String']['input'];
};

export type GetTransactionsInput = {
  chainId: Scalars['Int']['input'];
  cursor?: InputMaybe<Scalars['Cursor']['input']>;
  id: Scalars['ID']['input'];
  limit?: InputMaybe<Scalars['Int']['input']>;
};

export type GetTransactionsResult = {
  __typename?: 'GetTransactionsResult';
  cursor?: Maybe<Scalars['Cursor']['output']>;
  transactions: Array<TransactionResult>;
};

export type GetTrendingArtistsInput = {
  limit: Scalars['Int']['input'];
};

export type GetUpcomingDropsInput = {
  limit: Scalars['Int']['input'];
};

export type JoinCommunity = {
  __typename?: 'JoinCommunity';
  communityId: Scalars['ID']['output'];
  id: Scalars['ID']['output'];
};

export enum LabelType {
  Date = 'date',
  String = 'string'
}

export enum LaunchDateType {
  Immediate = 'immediate',
  Scheduled = 'scheduled'
}

export type LeaveCommunity = {
  __typename?: 'LeaveCommunity';
  communityId: Scalars['ID']['output'];
  id: Scalars['ID']['output'];
};

export type Link = DropLink | PostLink | ProfileLink;

export type MailingInput = {
  email: Scalars['String']['input'];
  type: Scalars['String']['input'];
};

export type Media = {
  __typename?: 'Media';
  contentType: Scalars['String']['output'];
  type: MediaType;
  url: Scalars['URL']['output'];
};

export type MediaInput = {
  contentType: Scalars['String']['input'];
  type: MediaType;
  url: Scalars['URL']['input'];
};

export type MediaStatus = {
  __typename?: 'MediaStatus';
  message: Scalars['String']['output'];
  status: MediaStatusType;
};

export enum MediaStatusType {
  Failed = 'FAILED',
  Processing = 'PROCESSING',
  Success = 'SUCCESS'
}

export enum MediaType {
  Image = 'image',
  Link = 'link',
  Video = 'video'
}

export type MetaTransaction = {
  __typename?: 'MetaTransaction';
  crossmint: Scalars['String']['output'];
  domain: DomainDefinition;
  message: MetaTransactionRequest;
  types: TypedDataDefinition;
};

export type MetaTransactionRequest = {
  __typename?: 'MetaTransactionRequest';
  data: Scalars['String']['output'];
  from: Scalars['String']['output'];
  gas: Scalars['String']['output'];
  nonce: Scalars['String']['output'];
  to: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

export type MetaTransactionRequestInput = {
  data: Scalars['String']['input'];
  from: Scalars['String']['input'];
  gas: Scalars['String']['input'];
  nonce: Scalars['String']['input'];
  to: Scalars['String']['input'];
  value: Scalars['String']['input'];
};

export type Model = {
  __typename?: 'Model';
  avatarConfiguration?: Maybe<AvatarConfiguration>;
  category: ModelCategoryType;
  elements: Scalars['JSONObject']['output'];
  file: Scalars['String']['output'];
  fileUrl: Scalars['String']['output'];
  free: Scalars['Boolean']['output'];
  icon: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  lights: Array<ModelLight>;
  listed: Scalars['Boolean']['output'];
  metals: Array<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  orthoOffset: Scalars['Float']['output'];
};

export enum ModelCategoryType {
  Avatar = 'avatar',
  Bottom = 'bottom',
  Room = 'room',
  Shoe = 'shoe',
  Top = 'top',
  Uncategorized = 'uncategorized'
}

export type ModelCompatibility = {
  __typename?: 'ModelCompatibility';
  fit: ModelFitType;
  location: ModelLocationType;
};

export enum ModelFitType {
  Loose = 'loose',
  Snug = 'snug'
}

export type ModelLight = {
  __typename?: 'ModelLight';
  intensity: Scalars['Float']['output'];
  x: Scalars['Float']['output'];
  y: Scalars['Float']['output'];
  z: Scalars['Float']['output'];
};

export type ModelLightInput = {
  intensity: Scalars['Float']['input'];
  x: Scalars['Float']['input'];
  y: Scalars['Float']['input'];
  z: Scalars['Float']['input'];
};

export enum ModelLocationType {
  Foot = 'foot',
  Head = 'head',
  Lowerarm = 'lowerarm',
  Lowerleg = 'lowerleg',
  Torso = 'torso',
  Upperarm = 'upperarm',
  Upperleg = 'upperleg',
  Waist = 'waist'
}

export enum ModerationStatus {
  Approved = 'approved',
  Pending = 'pending',
  Rejected = 'rejected'
}

export type Mutation = {
  __typename?: 'Mutation';
  activateSponsorship: Sponsorship;
  addAccountToMailingList?: Maybe<Scalars['Boolean']['output']>;
  addCollaborator?: Maybe<Collaborator>;
  addEmailToMailingList?: Maybe<Scalars['Boolean']['output']>;
  approveCollection?: Maybe<Collection>;
  archiveCollection?: Maybe<Scalars['Boolean']['output']>;
  archiveShopifyProduct: ShopifyProduct;
  challengeEmailAddress?: Maybe<Scalars['Boolean']['output']>;
  challengeWallet?: Maybe<WalletChallenge>;
  challengeWithdrawEarnings?: Maybe<WalletChallenge>;
  claimRevealed?: Maybe<Scalars['Boolean']['output']>;
  claimToken: Scalars['Boolean']['output'];
  claimTokenBenefit?: Maybe<TokenBenefit>;
  createAccount?: Maybe<Account>;
  createAddress?: Maybe<Address>;
  createAuction?: Maybe<Auction>;
  createBenefit?: Maybe<Benefit>;
  createCollection?: Maybe<Collection>;
  createCollectionBenefit: CollectionBenefit;
  createComment: Comment;
  createCommunity?: Maybe<Community>;
  createCommunityBenefit?: Maybe<CommunityBenefit>;
  createCommunityMember?: Maybe<CommunityMember>;
  createCustomWearable: CustomWearable;
  createDefaultShowroom: Showroom;
  createModel?: Maybe<Model>;
  createPendingMoment?: Maybe<PendingMoment>;
  createPost: Post;
  createReward: Reward;
  createSetting?: Maybe<Setting>;
  createShopifyProduct: ShopifyProduct;
  createSponsorship: Sponsorship;
  deployCollection?: Maybe<Scalars['Boolean']['output']>;
  depositThankYou?: Maybe<Scalars['Boolean']['output']>;
  endSale?: Maybe<Collection>;
  equipCustomWearable?: Maybe<Scalars['Boolean']['output']>;
  follow: Scalars['Boolean']['output'];
  getNextDesign: GetNextDesignReturn;
  loginByEmail?: Maybe<Account>;
  loginByFarcaster?: Maybe<Account>;
  loginByWallet?: Maybe<Account>;
  publishCollection?: Maybe<Collection>;
  publishShopifyProduct: ShopifyProduct;
  purchaseComplete: Token;
  purchaseToken: PurchaseTokenReturn;
  recordTip: Deposit;
  rejectCollection?: Maybe<Collection>;
  releaseToken: Scalars['Boolean']['output'];
  removeAccount?: Maybe<Scalars['Boolean']['output']>;
  removeAccountFromMailingList?: Maybe<Scalars['Boolean']['output']>;
  removeCollaborator?: Maybe<Scalars['Boolean']['output']>;
  removeCollectionBenefit: Scalars['Boolean']['output'];
  removeComment: Scalars['Boolean']['output'];
  removeCommunityBenefit?: Maybe<Scalars['Boolean']['output']>;
  removeCommunityMember?: Maybe<Scalars['Boolean']['output']>;
  removeFarcaster?: Maybe<Scalars['Boolean']['output']>;
  removePost: Scalars['Boolean']['output'];
  removeTwitter?: Maybe<Scalars['Boolean']['output']>;
  removeWalletAddress?: Maybe<Scalars['Boolean']['output']>;
  renderCollectionIcon?: Maybe<Scalars['Boolean']['output']>;
  requestReview?: Maybe<Collection>;
  rerenderToken: Scalars['Boolean']['output'];
  reserveToken: Scalars['String']['output'];
  resetCollection?: Maybe<Scalars['Boolean']['output']>;
  sendMetaTransaction: TransactionResult;
  setAccountRoles: Array<AccountRole>;
  sharePost: Post;
  shareReward: Scalars['Boolean']['output'];
  signMetaTransaction: Scalars['String']['output'];
  tipArtist: Deposit;
  tipThankYou?: Maybe<Scalars['Boolean']['output']>;
  toggleListedCollection?: Maybe<Collection>;
  toggleReaction: Scalars['Boolean']['output'];
  transferToken: TransferTokenResult;
  twitterAccessToken: TwitterAccessToken;
  twitterRequestToken: TwitterAuthResult;
  unarchiveCollection?: Maybe<Scalars['Boolean']['output']>;
  unfollow: Scalars['Boolean']['output'];
  unpublishCollection?: Maybe<Collection>;
  updateAccount?: Maybe<Account>;
  updateAddress?: Maybe<Address>;
  updateAuction?: Maybe<Auction>;
  updateBenefit?: Maybe<Benefit>;
  updateCollection?: Maybe<Collection>;
  updateCollectionBenefit: CollectionBenefit;
  updateComment: Comment;
  updateCommunity?: Maybe<Community>;
  updateCommunityMember?: Maybe<CommunityMember>;
  updateCustomWearable: CustomWearable;
  updateGenesisBenefit?: Maybe<Scalars['Boolean']['output']>;
  updateModel?: Maybe<Model>;
  updatePendingMoment?: Maybe<PendingMoment>;
  updatePost: Post;
  updateProfile: Profile;
  updateReward: Reward;
  updateSetting?: Maybe<Setting>;
  updateShowroom: Showroom;
  updateSponsorship: Sponsorship;
  verifyEmailAddress?: Maybe<Scalars['Boolean']['output']>;
  verifyWallet?: Maybe<Address>;
  viewPost: Post;
  withdrawEarnings?: Maybe<Scalars['String']['output']>;
};


export type MutationActivateSponsorshipArgs = {
  input: ActivateSponsorshipInput;
};


export type MutationAddAccountToMailingListArgs = {
  type: Scalars['ID']['input'];
};


export type MutationAddCollaboratorArgs = {
  input?: InputMaybe<AddCollaboratorInput>;
};


export type MutationAddEmailToMailingListArgs = {
  input: MailingInput;
};


export type MutationApproveCollectionArgs = {
  input: ApproveCollectionInput;
};


export type MutationArchiveCollectionArgs = {
  input: ArchiveCollectionInput;
};


export type MutationArchiveShopifyProductArgs = {
  input: ArchiveShopifyProductInput;
};


export type MutationChallengeEmailAddressArgs = {
  input: ChallengeEmailAddressInput;
};


export type MutationChallengeWalletArgs = {
  input: ChallengeWalletInput;
};


export type MutationChallengeWithdrawEarningsArgs = {
  input: ChallengeWithdrawEarningsInput;
};


export type MutationClaimRevealedArgs = {
  input: ClaimRevealedInput;
};


export type MutationClaimTokenArgs = {
  input: ClaimTokenInput;
};


export type MutationClaimTokenBenefitArgs = {
  tokenBenefitId: Scalars['ID']['input'];
};


export type MutationCreateAccountArgs = {
  input: CreateAccountInput;
};


export type MutationCreateAddressArgs = {
  input: CreateAddressInput;
};


export type MutationCreateAuctionArgs = {
  input: CreateAuctionInput;
};


export type MutationCreateBenefitArgs = {
  input: CreateBenefitInput;
};


export type MutationCreateCollectionArgs = {
  input: CreateCollectionInput;
};


export type MutationCreateCollectionBenefitArgs = {
  input: CreateCollectionBenefitInput;
};


export type MutationCreateCommentArgs = {
  input: CreateCommentInput;
};


export type MutationCreateCommunityArgs = {
  input: CreateCommunityInput;
};


export type MutationCreateCommunityBenefitArgs = {
  input: CreateCommunityBenefitInput;
};


export type MutationCreateCommunityMemberArgs = {
  input: CreateCommunityMemberInput;
};


export type MutationCreateCustomWearableArgs = {
  input: CreateCustomWearableInput;
};


export type MutationCreateDefaultShowroomArgs = {
  input: CreateDefaultShowroomInput;
};


export type MutationCreateModelArgs = {
  input: CreateModelInput;
};


export type MutationCreatePendingMomentArgs = {
  input: CreatePendingMomentInput;
};


export type MutationCreatePostArgs = {
  input: CreatePostInput;
};


export type MutationCreateRewardArgs = {
  input: CreateRewardInput;
};


export type MutationCreateSettingArgs = {
  input: SettingInput;
};


export type MutationCreateShopifyProductArgs = {
  input: CreateShopifyProductInput;
};


export type MutationCreateSponsorshipArgs = {
  input: CreateSponsorshipInput;
};


export type MutationDeployCollectionArgs = {
  input: DeployCollectionInput;
};


export type MutationDepositThankYouArgs = {
  input: DepositThankYouInput;
};


export type MutationEndSaleArgs = {
  input: EndSaleInput;
};


export type MutationEquipCustomWearableArgs = {
  input: EquipCustomWearableInput;
};


export type MutationFollowArgs = {
  id: Scalars['ID']['input'];
};


export type MutationGetNextDesignArgs = {
  input: GetNextDesignInput;
};


export type MutationLoginByEmailArgs = {
  email: Scalars['String']['input'];
};


export type MutationLoginByFarcasterArgs = {
  address: Scalars['String']['input'];
};


export type MutationLoginByWalletArgs = {
  address: Scalars['String']['input'];
  connectionType: Scalars['String']['input'];
};


export type MutationPublishCollectionArgs = {
  input: PublishCollectionInput;
};


export type MutationPublishShopifyProductArgs = {
  input: PublishShopifyProductInput;
};


export type MutationPurchaseCompleteArgs = {
  input: PurchaseCompleteInput;
};


export type MutationPurchaseTokenArgs = {
  input: PurchaseTokenInput;
};


export type MutationRecordTipArgs = {
  input: RecordTipInput;
};


export type MutationRejectCollectionArgs = {
  input: RejectCollectionInput;
};


export type MutationReleaseTokenArgs = {
  input: ReleaseTokenInput;
};


export type MutationRemoveAccountArgs = {
  id: Scalars['ID']['input'];
};


export type MutationRemoveAccountFromMailingListArgs = {
  type: Scalars['ID']['input'];
};


export type MutationRemoveCollaboratorArgs = {
  input?: InputMaybe<RemoveCollaboratorInput>;
};


export type MutationRemoveCollectionBenefitArgs = {
  input: RemoveCollectionBenefitInput;
};


export type MutationRemoveCommentArgs = {
  id: Scalars['ID']['input'];
};


export type MutationRemoveCommunityBenefitArgs = {
  input: RemoveCommunityBenefitInput;
};


export type MutationRemoveCommunityMemberArgs = {
  input: RemoveCommunityMemberInput;
};


export type MutationRemoveFarcasterArgs = {
  id: Scalars['ID']['input'];
};


export type MutationRemovePostArgs = {
  id: Scalars['ID']['input'];
};


export type MutationRemoveTwitterArgs = {
  id: Scalars['ID']['input'];
};


export type MutationRemoveWalletAddressArgs = {
  input: RemoveWalletInput;
};


export type MutationRenderCollectionIconArgs = {
  input: RenderCollectionIconInput;
};


export type MutationRequestReviewArgs = {
  input: RequestReviewInput;
};


export type MutationRerenderTokenArgs = {
  input: RerenderTokenInput;
};


export type MutationReserveTokenArgs = {
  input: ReserveTokenInput;
};


export type MutationResetCollectionArgs = {
  input: ResetCollectionInput;
};


export type MutationSendMetaTransactionArgs = {
  input: SendMetaTransactionInput;
};


export type MutationSetAccountRolesArgs = {
  input: SetAccountRolesInput;
};


export type MutationSharePostArgs = {
  id: Scalars['ID']['input'];
};


export type MutationShareRewardArgs = {
  id: Scalars['ID']['input'];
};


export type MutationSignMetaTransactionArgs = {
  input: SignMetaTransactionInput;
};


export type MutationTipArtistArgs = {
  input: TipArtistInput;
};


export type MutationTipThankYouArgs = {
  input: TipThankYouInput;
};


export type MutationToggleListedCollectionArgs = {
  input: ToggleListedCollectionInput;
};


export type MutationToggleReactionArgs = {
  input: ToggleReactionInput;
};


export type MutationTransferTokenArgs = {
  input: TransferTokenInput;
};


export type MutationTwitterAccessTokenArgs = {
  input?: InputMaybe<TwitterAccessTokenInput>;
};


export type MutationTwitterRequestTokenArgs = {
  input?: InputMaybe<TwitterRequestTokenInput>;
};


export type MutationUnarchiveCollectionArgs = {
  input: UnarchiveCollectionInput;
};


export type MutationUnfollowArgs = {
  id: Scalars['ID']['input'];
};


export type MutationUnpublishCollectionArgs = {
  input: UnpublishCollectionInput;
};


export type MutationUpdateAccountArgs = {
  input: AccountInput;
};


export type MutationUpdateAddressArgs = {
  input: UpdateAddressInput;
};


export type MutationUpdateAuctionArgs = {
  input: UpdateAuctionInput;
};


export type MutationUpdateBenefitArgs = {
  input: UpdateBenefitInput;
};


export type MutationUpdateCollectionArgs = {
  input: UpdateCollectionInput;
};


export type MutationUpdateCollectionBenefitArgs = {
  input: UpdateCollectionBenefitInput;
};


export type MutationUpdateCommentArgs = {
  input: UpdateCommentInput;
};


export type MutationUpdateCommunityArgs = {
  input: UpdateCommunityInput;
};


export type MutationUpdateCommunityMemberArgs = {
  input: UpdateCommunityMemberInput;
};


export type MutationUpdateCustomWearableArgs = {
  input: UpdateCustomWearableInput;
};


export type MutationUpdateGenesisBenefitArgs = {
  input: UpdateGenesisBenefitInput;
};


export type MutationUpdateModelArgs = {
  input: UpdateModelInput;
};


export type MutationUpdatePendingMomentArgs = {
  input: UpdatePendingMomentInput;
};


export type MutationUpdatePostArgs = {
  input: UpdatePostInput;
};


export type MutationUpdateProfileArgs = {
  input: UpdateProfileInput;
};


export type MutationUpdateRewardArgs = {
  input: UpdateRewardInput;
};


export type MutationUpdateSettingArgs = {
  input: SettingInput;
};


export type MutationUpdateShowroomArgs = {
  input?: InputMaybe<UpdateShowroomInput>;
};


export type MutationUpdateSponsorshipArgs = {
  input: UpdateSponsorshipInput;
};


export type MutationVerifyEmailAddressArgs = {
  input: VerifyEmailAddressInput;
};


export type MutationVerifyWalletArgs = {
  input: VerifyWalletInput;
};


export type MutationViewPostArgs = {
  id: Scalars['ID']['input'];
};


export type MutationWithdrawEarningsArgs = {
  input: WithdrawEarningsInput;
};

export type NftAsset = {
  __typename?: 'NftAsset';
  address: Scalars['String']['output'];
  contentType: Scalars['String']['output'];
  isImage: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  tokenId: Scalars['String']['output'];
  url: Scalars['URL']['output'];
};

export type PaginateModelsResult = {
  __typename?: 'PaginateModelsResult';
  cursor?: Maybe<Scalars['Cursor']['output']>;
  models: Array<Model>;
};

export type PaginatedCollectionsResult = {
  __typename?: 'PaginatedCollectionsResult';
  collections: Array<Collection>;
  cursor?: Maybe<Scalars['Cursor']['output']>;
};

export type PaginatedCommentsResult = {
  __typename?: 'PaginatedCommentsResult';
  comments: Array<Comment>;
  cursor?: Maybe<Scalars['Cursor']['output']>;
};

export type PaginatedCommunitiesResult = {
  __typename?: 'PaginatedCommunitiesResult';
  communities?: Maybe<Array<Community>>;
  cursor?: Maybe<Scalars['Cursor']['output']>;
};

export type PaginatedCustomWearables = {
  __typename?: 'PaginatedCustomWearables';
  cursor?: Maybe<Scalars['Cursor']['output']>;
  customWearables: Array<CustomWearable>;
};

export type PaginatedDeposits = {
  __typename?: 'PaginatedDeposits';
  cursor?: Maybe<Scalars['Cursor']['output']>;
  deposits: Array<Deposit>;
};

export type PaginatedDropResult = {
  __typename?: 'PaginatedDropResult';
  cursor?: Maybe<Scalars['Cursor']['output']>;
  drops: Array<CommunityBenefit>;
};

export type PaginatedMembersResult = {
  __typename?: 'PaginatedMembersResult';
  cursor?: Maybe<Scalars['Cursor']['output']>;
  members: Array<CommunityMember>;
};

export type PaginatedMomentResult = {
  __typename?: 'PaginatedMomentResult';
  cursor?: Maybe<Scalars['Cursor']['output']>;
  moments: Array<CommunityBenefit>;
};

export type PaginatedNftAssetsResult = {
  __typename?: 'PaginatedNftAssetsResult';
  assets: Array<NftAsset>;
  cursor?: Maybe<Scalars['String']['output']>;
};

export type PaginatedPostsResult = {
  __typename?: 'PaginatedPostsResult';
  cursor?: Maybe<Scalars['Cursor']['output']>;
  posts: Array<Post>;
};

export type PaginatedProfileResult = {
  __typename?: 'PaginatedProfileResult';
  cursor?: Maybe<Scalars['Cursor']['output']>;
  profiles: Array<Profile>;
};

export type PaginatedTipEarnings = {
  __typename?: 'PaginatedTipEarnings';
  cursor?: Maybe<Scalars['Cursor']['output']>;
  tips: Array<Tip>;
};

export type PaginatedTokenResult = {
  __typename?: 'PaginatedTokenResult';
  cursor?: Maybe<Scalars['Cursor']['output']>;
  tokens: Array<Token>;
};

export type PaginatedTokensResult = {
  __typename?: 'PaginatedTokensResult';
  cursor?: Maybe<Scalars['Cursor']['output']>;
  tokens: Array<Token>;
};

export type PendingMoment = {
  __typename?: 'PendingMoment';
  accountId: Scalars['ID']['output'];
  benefitMetadata: Scalars['JSON']['output'];
  categories?: Maybe<Array<BenefitCategory>>;
  chainId: Scalars['Int']['output'];
  collectionMetadata: Scalars['JSON']['output'];
  created: Scalars['Int']['output'];
  description: Scalars['String']['output'];
  dropType: DropType;
  icon: Scalars['URL']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  price?: Maybe<Scalars['Float']['output']>;
  status: PendingMomentStatus;
  statusMessage?: Maybe<Scalars['String']['output']>;
  updated: Scalars['Int']['output'];
};

export type PendingMomentComplete = {
  __typename?: 'PendingMomentComplete';
  id: Scalars['ID']['output'];
  message?: Maybe<Scalars['String']['output']>;
  pendingMomentId: Scalars['ID']['output'];
};

export type PendingMomentFailed = {
  __typename?: 'PendingMomentFailed';
  id: Scalars['ID']['output'];
  message?: Maybe<Scalars['String']['output']>;
  pendingMomentId: Scalars['ID']['output'];
};

export type PendingMomentPending = {
  __typename?: 'PendingMomentPending';
  id: Scalars['ID']['output'];
  message?: Maybe<Scalars['String']['output']>;
  pendingMomentId: Scalars['ID']['output'];
};

export type PendingMomentProcessing = {
  __typename?: 'PendingMomentProcessing';
  id: Scalars['ID']['output'];
  message?: Maybe<Scalars['String']['output']>;
  pendingMomentId: Scalars['ID']['output'];
};

export enum PendingMomentStatus {
  Completed = 'completed',
  Failed = 'failed',
  Pending = 'pending',
  Processing = 'processing'
}

export type Plan = {
  __typename?: 'Plan';
  active: Scalars['Boolean']['output'];
  description: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  price: Scalars['Float']['output'];
  settings: PlanSettings;
  stripePriceId: Scalars['String']['output'];
};

export type PlanSettings = {
  __typename?: 'PlanSettings';
  benefits: Scalars['Int']['output'];
  bulletinBoard: Scalars['Boolean']['output'];
  emails: Scalars['Int']['output'];
  liveChat: Scalars['Boolean']['output'];
  members: Scalars['Int']['output'];
  rewards: Scalars['Int']['output'];
  transactionFee: Scalars['Float']['output'];
};

export enum PoseType {
  Bboy = 'bboy',
  BreakdanceUprock = 'breakdance_uprock',
  Hiphop = 'hiphop',
  Housedance = 'housedance',
  Idle_2 = 'idle_2',
  Idle_3 = 'idle_3',
  Idle_4 = 'idle_4',
  Idle_5 = 'idle_5',
  Idle_6 = 'idle_6',
  IdleMain = 'idle_main',
  Locking = 'locking',
  Silly = 'silly',
  Slide = 'slide',
  Twerk = 'twerk',
  Wave = 'wave'
}

export type Post = {
  __typename?: 'Post';
  content?: Maybe<Scalars['String']['output']>;
  created: Scalars['Int']['output'];
  id: Scalars['ID']['output'];
  locked: Scalars['Boolean']['output'];
  media: Array<Media>;
  moderation: ModerationStatus;
  profile: Profile;
  reaction?: Maybe<ReactionType>;
  seeMore: Scalars['Boolean']['output'];
  shortContent: Scalars['String']['output'];
  stats: PostStats;
  status: PostStatus;
  updated: Scalars['Int']['output'];
};

export type PostLink = {
  __typename?: 'PostLink';
  postId: Scalars['ID']['output'];
  profileId: Scalars['ID']['output'];
};

export type PostStats = {
  __typename?: 'PostStats';
  comments: Scalars['Int']['output'];
  impressions: Scalars['Int']['output'];
  reactions: Scalars['Int']['output'];
  shares: Scalars['Int']['output'];
  views: Scalars['Int']['output'];
};

export enum PostStatus {
  Archived = 'archived',
  Draft = 'draft',
  Published = 'published'
}

export type PremintList = {
  __typename?: 'PremintList';
  appliesTo: Array<CollectionStatus>;
  link: Scalars['URL']['output'];
};

export type PremintListInput = {
  appliesTo: Array<CollectionStatus>;
  link: Scalars['URL']['input'];
};

export type Profile = {
  __typename?: 'Profile';
  accountId: Scalars['ID']['output'];
  banner?: Maybe<Scalars['URL']['output']>;
  bio?: Maybe<Scalars['String']['output']>;
  collectedCount?: Maybe<Scalars['Int']['output']>;
  collectorCount?: Maybe<Scalars['Int']['output']>;
  displayName: Scalars['String']['output'];
  dropCount?: Maybe<Scalars['Int']['output']>;
  farcasterUsername?: Maybe<Scalars['String']['output']>;
  fid?: Maybe<Scalars['String']['output']>;
  followerCount?: Maybe<Scalars['Int']['output']>;
  followingCount?: Maybe<Scalars['Int']['output']>;
  icon?: Maybe<Scalars['URL']['output']>;
  id: Scalars['ID']['output'];
  isFollowing?: Maybe<Scalars['Boolean']['output']>;
  isSponsor?: Maybe<Scalars['Boolean']['output']>;
  isStaff?: Maybe<Scalars['Boolean']['output']>;
  momentCount?: Maybe<Scalars['Int']['output']>;
  roles: Array<AccountRole>;
  sponsoredDropCount?: Maybe<Scalars['Int']['output']>;
  tipsGiven?: Maybe<Scalars['Int']['output']>;
  tipsReceived?: Maybe<Scalars['Int']['output']>;
  twitterId?: Maybe<Scalars['String']['output']>;
  twitterUsername?: Maybe<Scalars['String']['output']>;
  username: Scalars['String']['output'];
  verified?: Maybe<Scalars['Boolean']['output']>;
  verifiedType: VerifiedType;
  website?: Maybe<Scalars['URL']['output']>;
};

export type ProfileData = {
  __typename?: 'ProfileData';
  profile: Profile;
  profileId: Scalars['ID']['output'];
  socialData: DataSet;
};

export type ProfileLink = {
  __typename?: 'ProfileLink';
  profileId: Scalars['ID']['output'];
};

export type PublishCollectionInput = {
  chainId: Scalars['Int']['input'];
  contractId: Scalars['String']['input'];
  id: Scalars['ID']['input'];
};

export type PublishShopifyProductInput = {
  collectionId: Scalars['ID']['input'];
  productId: Scalars['String']['input'];
};

export type PurchaseCompleteInput = {
  accountId: Scalars['ID']['input'];
  address: Scalars['ID']['input'];
  collectionId: Scalars['ID']['input'];
  price: Scalars['Float']['input'];
  tokenId: Scalars['Int']['input'];
  txHash: Scalars['String']['input'];
  value: Scalars['Float']['input'];
};

export type PurchaseTokenInput = {
  fundingSource: FundingSource;
  optionalPrice?: InputMaybe<Scalars['Float']['input']>;
  payerAccountId?: InputMaybe<Scalars['ID']['input']>;
  token: Scalars['String']['input'];
};

export type PurchaseTokenReturn = {
  __typename?: 'PurchaseTokenReturn';
  contractId: Scalars['String']['output'];
  expiryBlock: Scalars['Int']['output'];
  price: Scalars['String']['output'];
  result?: Maybe<Scalars['String']['output']>;
  signature: Scalars['String']['output'];
  tokenId: Scalars['Int']['output'];
};

export type Query = {
  __typename?: 'Query';
  followingFeed: FeedResult;
  forYouMoments: GetMomentsResult;
  getAccount?: Maybe<Account>;
  getAuction?: Maybe<Auction>;
  getBalances: GetBalancesResult;
  getBenefit?: Maybe<Benefit>;
  getBenefits: Array<CommunityBenefit>;
  getClaimEarnings?: Maybe<Array<ClaimEarnings>>;
  getCollaborators: Array<Maybe<Collaborator>>;
  getCollectedData: DataSet;
  getCollection: Collection;
  getCollectionBenefits: Array<CollectionBenefit>;
  getCollectionByName: Collection;
  getCollectionBySlug?: Maybe<Collection>;
  getCollections?: Maybe<PaginatedCollectionsResult>;
  getCollectionsByAccount?: Maybe<PaginatedCollectionsResult>;
  getCollectionsByStatus?: Maybe<PaginatedCollectionsResult>;
  getCollectorList?: Maybe<Scalars['URL']['output']>;
  getCollectors: PaginatedProfileResult;
  getComments: PaginatedCommentsResult;
  getCommunities: PaginatedCommunitiesResult;
  getCommunitiesByAccount: PaginatedCommunitiesResult;
  getCommunity?: Maybe<Community>;
  getCommunityMembers: PaginatedMembersResult;
  getCustomWearable: CustomWearable;
  getCustomWearables?: Maybe<PaginatedCustomWearables>;
  getDeposits: PaginatedDeposits;
  getDropAnalytics: DropAnalytics;
  getDropsData: DataSet;
  getETHPrice?: Maybe<Scalars['String']['output']>;
  getEarningsSummary?: Maybe<Array<EarningsSummary>>;
  getEmailList?: Maybe<Scalars['URL']['output']>;
  getEngagementData: DataSet;
  getExchangePrices?: Maybe<GetExchangePricesResult>;
  getFollowerData: DataSet;
  getFollowers: PaginatedProfileResult;
  getFollowing: PaginatedProfileResult;
  getGenesisBenefits?: Maybe<Array<Maybe<AccountBenefit>>>;
  getLatestDrops?: Maybe<Array<Reward>>;
  getListedModels: Array<Model>;
  getMediaStatus: MediaStatus;
  getMinterAccount?: Maybe<Scalars['String']['output']>;
  getModelById: Model;
  getModels: PaginateModelsResult;
  getMoments: GetMomentsResult;
  getMomentsSince: GetMomentsResult;
  getNewMomentCount: Scalars['Int']['output'];
  getNftAssetsByAddress: PaginatedNftAssetsResult;
  getPendingMoment: PendingMoment;
  getPendingMoments: Array<PendingMoment>;
  getPlan: Plan;
  getPlans: Array<Plan>;
  getPost: Post;
  getPosts: PaginatedPostsResult;
  getProfile: Profile;
  getProfileData: ProfileData;
  getProfileDrops: PaginatedDropResult;
  getProfileMoments: PaginatedMomentResult;
  getProfileSponsoredDrops: PaginatedDropResult;
  getProfileTokens: PaginatedTokenResult;
  getPublishedCollection?: Maybe<Collection>;
  getRevenueSplitter?: Maybe<RevenueSplitterResult>;
  getReward: Reward;
  getRewardCollectors: PaginatedProfileResult;
  getSetting?: Maybe<Setting>;
  getShowroom: Showroom;
  getShowrooms: Array<Showroom>;
  getSponsorship: Sponsorship;
  getSponsorshipEarnings?: Maybe<Array<SponsorshipEarnings>>;
  getSponsorshipTransaction: SponsorshipTransaction;
  getSummaryData: SummaryStatisticsResult;
  getSupportedNetworks: SupportedNetworks;
  getTips: PaginatedTipEarnings;
  getToken?: Maybe<Token>;
  getTokenDetails?: Maybe<TokenDetailsResult>;
  getTokenDownloadUrl?: Maybe<Scalars['String']['output']>;
  getTokenGlbUrl?: Maybe<Scalars['String']['output']>;
  getTokenSupply?: Maybe<TokenSupply>;
  getTokenTransactionHash?: Maybe<Scalars['String']['output']>;
  getTokenUsdzUrl?: Maybe<Scalars['String']['output']>;
  getTokens?: Maybe<PaginatedTokensResult>;
  getTokensByAccount?: Maybe<PaginatedTokensResult>;
  getTokensByCollection?: Maybe<PaginatedTokensResult>;
  getTopCollectors?: Maybe<Array<TopCollector>>;
  getTopCollectorsData: DataSet;
  getTopContentData: DataSet;
  getTopTippedMoments?: Maybe<Array<TopTippedMoment>>;
  getTopTippers?: Maybe<Array<TopTipper>>;
  getTopTippingStreak?: Maybe<Array<TopTippingStreak>>;
  getTopTippingStreakRank: Scalars['Int']['output'];
  getTopTipsGiven?: Maybe<Array<TopTipGiven>>;
  getTopTipsGivenRank: Scalars['Int']['output'];
  getTopTipsReceived?: Maybe<Array<TopTipReceived>>;
  getTopTipsReceivedRank: Scalars['Int']['output'];
  getTransactions: GetTransactionsResult;
  getTrendingArtists?: Maybe<Array<TrendingArtist>>;
  getUpcomingDrops?: Maybe<Array<Reward>>;
  getWithdrawals?: Maybe<Array<EarningsWithdrawal>>;
  hasAccess?: Maybe<Scalars['Boolean']['output']>;
  hasEarlyAccess?: Maybe<Scalars['Boolean']['output']>;
  isGenesisHolder?: Maybe<Scalars['Boolean']['output']>;
  isMemberOfMailingList?: Maybe<Scalars['Boolean']['output']>;
  isOwnerOfCollection?: Maybe<Scalars['Boolean']['output']>;
  isTokenHolder?: Maybe<Scalars['Boolean']['output']>;
  relatedFeed: FeedResult;
  requestUpload: RequestUploadResponse;
  requestUploadForShare: RequestUploadForShareResponse;
  searchAccountsByUsername: SearchAccountsByUsernameResult;
  suggestedMoments: GetMomentsResult;
  validateTokenGate?: Maybe<Scalars['Boolean']['output']>;
};


export type QueryFollowingFeedArgs = {
  input?: InputMaybe<FeedInput>;
};


export type QueryForYouMomentsArgs = {
  input: GetMomentsInput;
};


export type QueryGetAccountArgs = {
  id: Scalars['ID']['input'];
};


export type QueryGetAuctionArgs = {
  collectionId: Scalars['ID']['input'];
};


export type QueryGetBalancesArgs = {
  input?: InputMaybe<GetBalancesInput>;
};


export type QueryGetBenefitArgs = {
  id: Scalars['ID']['input'];
};


export type QueryGetBenefitsArgs = {
  communityId: Scalars['ID']['input'];
};


export type QueryGetClaimEarningsArgs = {
  id: Scalars['ID']['input'];
};


export type QueryGetCollaboratorsArgs = {
  input: GetCollaboratorsInput;
};


export type QueryGetCollectedDataArgs = {
  input: GetDataInput;
};


export type QueryGetCollectionArgs = {
  id: Scalars['ID']['input'];
};


export type QueryGetCollectionBenefitsArgs = {
  collectionId: Scalars['ID']['input'];
};


export type QueryGetCollectionByNameArgs = {
  name: Scalars['String']['input'];
};


export type QueryGetCollectionBySlugArgs = {
  slug: Scalars['String']['input'];
};


export type QueryGetCollectionsArgs = {
  input?: InputMaybe<GetCollectionsInput>;
};


export type QueryGetCollectionsByAccountArgs = {
  cursor?: InputMaybe<Scalars['Cursor']['input']>;
  id: Scalars['ID']['input'];
  limit?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryGetCollectionsByStatusArgs = {
  cursor?: InputMaybe<Scalars['Cursor']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  status: CollectionStatus;
};


export type QueryGetCollectorListArgs = {
  input: GetCollectorListInput;
};


export type QueryGetCollectorsArgs = {
  input: GetCollectorsInput;
};


export type QueryGetCommentsArgs = {
  input: GetCommentsInput;
};


export type QueryGetCommunitiesArgs = {
  input: GetCommunitiesInput;
};


export type QueryGetCommunitiesByAccountArgs = {
  input?: InputMaybe<GetCommunitiesByAccountInput>;
};


export type QueryGetCommunityArgs = {
  id: Scalars['ID']['input'];
};


export type QueryGetCommunityMembersArgs = {
  input: GetCommunityMembersInput;
};


export type QueryGetCustomWearableArgs = {
  id: Scalars['ID']['input'];
};


export type QueryGetCustomWearablesArgs = {
  input?: InputMaybe<GetCustomWearablesInput>;
};


export type QueryGetDepositsArgs = {
  input: GetDepositsInput;
};


export type QueryGetDropAnalyticsArgs = {
  input: GetDropAnalyticsInput;
};


export type QueryGetDropsDataArgs = {
  input: GetDataInput;
};


export type QueryGetEarningsSummaryArgs = {
  id: Scalars['ID']['input'];
};


export type QueryGetEmailListArgs = {
  id: Scalars['ID']['input'];
};


export type QueryGetEngagementDataArgs = {
  input: GetDataInput;
};


export type QueryGetFollowerDataArgs = {
  input: GetDataInput;
};


export type QueryGetFollowersArgs = {
  input: GetFollowersInput;
};


export type QueryGetFollowingArgs = {
  input: GetFollowingInput;
};


export type QueryGetGenesisBenefitsArgs = {
  accountId: Scalars['ID']['input'];
};


export type QueryGetLatestDropsArgs = {
  input: GetLatestDropsInput;
};


export type QueryGetMediaStatusArgs = {
  key: Scalars['String']['input'];
};


export type QueryGetModelByIdArgs = {
  id: Scalars['ID']['input'];
};


export type QueryGetModelsArgs = {
  input?: InputMaybe<GetModelsInput>;
};


export type QueryGetMomentsArgs = {
  input: GetMomentsInput;
};


export type QueryGetMomentsSinceArgs = {
  input: GetMomentsSinceInput;
};


export type QueryGetNewMomentCountArgs = {
  input: GetNewMomentCountInput;
};


export type QueryGetNftAssetsByAddressArgs = {
  input: GetNftAssetsByAddressInput;
};


export type QueryGetPendingMomentArgs = {
  id: Scalars['ID']['input'];
};


export type QueryGetPendingMomentsArgs = {
  input: GetPendingMomentsInput;
};


export type QueryGetPlanArgs = {
  id: Scalars['ID']['input'];
};


export type QueryGetPostArgs = {
  id: Scalars['ID']['input'];
};


export type QueryGetPostsArgs = {
  input: GetPostsInput;
};


export type QueryGetProfileArgs = {
  id: Scalars['ID']['input'];
};


export type QueryGetProfileDataArgs = {
  input: GetProfileDataInput;
};


export type QueryGetProfileDropsArgs = {
  input: GetProfileDropsInput;
};


export type QueryGetProfileMomentsArgs = {
  input: GetProfileMomentsInput;
};


export type QueryGetProfileSponsoredDropsArgs = {
  input: GetProfileDropsInput;
};


export type QueryGetProfileTokensArgs = {
  input: GetProfileTokensInput;
};


export type QueryGetPublishedCollectionArgs = {
  id: Scalars['ID']['input'];
};


export type QueryGetRevenueSplitterArgs = {
  chainId: Scalars['Int']['input'];
};


export type QueryGetRewardArgs = {
  benefitId: Scalars['ID']['input'];
  communityId: Scalars['ID']['input'];
};


export type QueryGetRewardCollectorsArgs = {
  input?: InputMaybe<GetRewardCollectorsInput>;
};


export type QueryGetSettingArgs = {
  id: Scalars['ID']['input'];
};


export type QueryGetShowroomArgs = {
  id: Scalars['ID']['input'];
};


export type QueryGetShowroomsArgs = {
  input?: InputMaybe<GetShowroomsInput>;
};


export type QueryGetSponsorshipArgs = {
  id: Scalars['ID']['input'];
};


export type QueryGetSponsorshipEarningsArgs = {
  id: Scalars['ID']['input'];
};


export type QueryGetSponsorshipTransactionArgs = {
  id: Scalars['ID']['input'];
};


export type QueryGetSummaryDataArgs = {
  input: GetDataInput;
};


export type QueryGetTipsArgs = {
  input: GetTipsInput;
};


export type QueryGetTokenArgs = {
  benefitId: Scalars['ID']['input'];
  tokenId: Scalars['Int']['input'];
};


export type QueryGetTokenDetailsArgs = {
  collectionId: Scalars['ID']['input'];
  tokenId: Scalars['Int']['input'];
};


export type QueryGetTokenDownloadUrlArgs = {
  collectionId: Scalars['ID']['input'];
  tokenId: Scalars['Int']['input'];
};


export type QueryGetTokenGlbUrlArgs = {
  collectionId: Scalars['ID']['input'];
  tokenId: Scalars['Int']['input'];
};


export type QueryGetTokenSupplyArgs = {
  collectionId: Scalars['ID']['input'];
};


export type QueryGetTokenTransactionHashArgs = {
  address: Scalars['String']['input'];
  collectionId: Scalars['ID']['input'];
  tokenId: Scalars['Int']['input'];
};


export type QueryGetTokenUsdzUrlArgs = {
  collectionId: Scalars['ID']['input'];
  tokenId: Scalars['Int']['input'];
};


export type QueryGetTokensArgs = {
  input?: InputMaybe<GetTokensInput>;
};


export type QueryGetTokensByAccountArgs = {
  input: GetTokensByAccountInput;
};


export type QueryGetTokensByCollectionArgs = {
  cursor?: InputMaybe<Scalars['Cursor']['input']>;
  id: Scalars['ID']['input'];
  limit?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryGetTopCollectorsArgs = {
  input: GetTopCollectorsInput;
};


export type QueryGetTopCollectorsDataArgs = {
  input: GetDataInput;
};


export type QueryGetTopContentDataArgs = {
  input: GetDataInput;
};


export type QueryGetTopTippedMomentsArgs = {
  input: GetTopTippedMomentsInput;
};


export type QueryGetTopTippersArgs = {
  input: GetTopTippersInput;
};


export type QueryGetTopTippingStreakArgs = {
  input: GetTopTippingStreakInput;
};


export type QueryGetTopTippingStreakRankArgs = {
  input: GetTopTippingStreakRankInput;
};


export type QueryGetTopTipsGivenArgs = {
  input: GetTopTipsGivenInput;
};


export type QueryGetTopTipsGivenRankArgs = {
  input: GetTopTipsGivenRankInput;
};


export type QueryGetTopTipsReceivedArgs = {
  input: GetTopTipsReceivedInput;
};


export type QueryGetTopTipsReceivedRankArgs = {
  input: GetTopTipsReceivedRankInput;
};


export type QueryGetTransactionsArgs = {
  input: GetTransactionsInput;
};


export type QueryGetTrendingArtistsArgs = {
  input: GetTrendingArtistsInput;
};


export type QueryGetUpcomingDropsArgs = {
  input: GetUpcomingDropsInput;
};


export type QueryGetWithdrawalsArgs = {
  id: Scalars['ID']['input'];
};


export type QueryHasAccessArgs = {
  collectionId: Scalars['ID']['input'];
  status?: InputMaybe<CollectionStatus>;
};


export type QueryHasEarlyAccessArgs = {
  collectionId: Scalars['ID']['input'];
};


export type QueryIsGenesisHolderArgs = {
  id: Scalars['ID']['input'];
};


export type QueryIsMemberOfMailingListArgs = {
  type: Scalars['ID']['input'];
};


export type QueryIsOwnerOfCollectionArgs = {
  collectionId: Scalars['ID']['input'];
};


export type QueryIsTokenHolderArgs = {
  account: Scalars['String']['input'];
  address: Scalars['String']['input'];
  chainId: Scalars['Int']['input'];
  tokenId: Scalars['Int']['input'];
};


export type QueryRelatedFeedArgs = {
  input: FeedInput;
};


export type QueryRequestUploadArgs = {
  file: Scalars['String']['input'];
  id: Scalars['String']['input'];
  mimeType: Scalars['String']['input'];
};


export type QueryRequestUploadForShareArgs = {
  description: Scalars['String']['input'];
  file: Scalars['String']['input'];
  title: Scalars['String']['input'];
  type: Scalars['String']['input'];
};


export type QuerySearchAccountsByUsernameArgs = {
  input: SearchAccountsByUsernameInput;
};


export type QuerySuggestedMomentsArgs = {
  input: GetMomentsInput;
};


export type QueryValidateTokenGateArgs = {
  address: Scalars['String']['input'];
  chainId: Scalars['Int']['input'];
  collectionId: Scalars['ID']['input'];
};

export type Reaction = {
  __typename?: 'Reaction';
  accountId: Scalars['ID']['output'];
  reactionType: ReactionType;
  relationId: Scalars['ID']['output'];
  relationType: RelationType;
};

export enum ReactionType {
  Like = 'like'
}

export type RecordTipInput = {
  amount: Scalars['Float']['input'];
  depositorId: Scalars['ID']['input'];
  fundingSource: FundingSource;
  hash: Scalars['ID']['input'];
  recipientId: Scalars['ID']['input'];
  value: Scalars['Float']['input'];
};

export type RejectCollectionInput = {
  id: Scalars['ID']['input'];
  requestedChanges: Scalars['String']['input'];
};

export enum RelationType {
  Benefit = 'Benefit',
  Comment = 'Comment',
  Post = 'Post'
}

export type ReleaseTokenInput = {
  token: Scalars['String']['input'];
};

export type RemoveCollaboratorInput = {
  accountId: Scalars['ID']['input'];
  collectionId: Scalars['ID']['input'];
  id: Scalars['ID']['input'];
};

export type RemoveCollectionBenefitInput = {
  benefitId: Scalars['ID']['input'];
  collectionId: Scalars['ID']['input'];
};

export type RemoveCommunityBenefitInput = {
  benefitId: Scalars['ID']['input'];
  communityId: Scalars['ID']['input'];
};

export type RemoveCommunityMemberInput = {
  accountId: Scalars['ID']['input'];
  communityId: Scalars['ID']['input'];
};

export type RemoveWalletInput = {
  address: Scalars['String']['input'];
};

export type RenderCollectionIconInput = {
  code: Scalars['String']['input'];
  id: Scalars['ID']['input'];
};

export type Reputation = {
  __typename?: 'Reputation';
  blockchain?: Maybe<Scalars['JSONObject']['output']>;
  external?: Maybe<Scalars['JSONObject']['output']>;
  internal?: Maybe<Scalars['JSONObject']['output']>;
  score: Scalars['Int']['output'];
};

export type RequestReviewInput = {
  id: Scalars['ID']['input'];
};

export type RequestUploadForShareResponse = {
  __typename?: 'RequestUploadForShareResponse';
  fields: RequestUploadResponseFields;
  htmlUrl: Scalars['String']['output'];
  url: Scalars['String']['output'];
};

export type RequestUploadResponse = {
  __typename?: 'RequestUploadResponse';
  fields: RequestUploadResponseFields;
  url: Scalars['String']['output'];
};

export type RequestUploadResponseFields = {
  __typename?: 'RequestUploadResponseFields';
  Policy: Scalars['String']['output'];
  X_Amz_Algorithm: Scalars['String']['output'];
  X_Amz_Credential: Scalars['String']['output'];
  X_Amz_Date: Scalars['String']['output'];
  X_Amz_Security_Token: Scalars['String']['output'];
  X_Amz_Signature: Scalars['String']['output'];
  bucket: Scalars['String']['output'];
  key: Scalars['String']['output'];
};

export type RerenderTokenInput = {
  collectionId: Scalars['ID']['input'];
  tokenId: Scalars['Int']['input'];
};

export type ReserveTokenInput = {
  address: Scalars['ID']['input'];
  collectionId: Scalars['ID']['input'];
  design: Scalars['String']['input'];
};

export type ResetCollectionInput = {
  id: Scalars['ID']['input'];
};

export type RevenueSplitterResult = {
  __typename?: 'RevenueSplitterResult';
  address: Scalars['String']['output'];
  jsonInterface: Scalars['String']['output'];
};

export type Reward = {
  __typename?: 'Reward';
  benefit: Benefit;
  benefitId: Scalars['ID']['output'];
  collection: Collection;
  collectionId: Scalars['ID']['output'];
  community: Community;
  communityId: Scalars['ID']['output'];
  totalTips?: Maybe<Scalars['Int']['output']>;
};

export type SearchAccountsByUsernameInput = {
  searchText: Scalars['String']['input'];
};

export type SearchAccountsByUsernameResult = {
  __typename?: 'SearchAccountsByUsernameResult';
  accounts: Array<Account>;
};

export type SendMetaTransactionInput = {
  chainId: Scalars['Int']['input'];
  request: MetaTransactionRequestInput;
  signature: Scalars['String']['input'];
};

export type SetAccountRolesInput = {
  accountId: Scalars['ID']['input'];
  roles: Array<AccountRole>;
};

export type Setting = {
  __typename?: 'Setting';
  data: Scalars['JSONObject']['output'];
  id: Scalars['ID']['output'];
};

export type SettingInput = {
  data: Scalars['JSONObject']['input'];
  id: Scalars['ID']['input'];
};

export type ShopifyProduct = {
  __typename?: 'ShopifyProduct';
  id: Scalars['String']['output'];
  url?: Maybe<Scalars['String']['output']>;
  variantId: Scalars['String']['output'];
};

export type Showroom = {
  __typename?: 'Showroom';
  accountId: Scalars['ID']['output'];
  avatar: ShowroomModel;
  garments: Array<ShowroomModel>;
  id: Scalars['ID']['output'];
  pose: PoseType;
  room: ShowroomModel;
};

export type ShowroomModel = CustomWearableModel | DefaultModel | TokenModel;

export type ShowroomModelInput = {
  collectionId?: InputMaybe<Scalars['ID']['input']>;
  customWearableId?: InputMaybe<Scalars['ID']['input']>;
  modelId: Scalars['ID']['input'];
  tokenId?: InputMaybe<Scalars['Int']['input']>;
};

export type SignMetaTransactionInput = {
  chainId: Scalars['Int']['input'];
  message: Scalars['String']['input'];
  token: Scalars['String']['input'];
};

export type SocialLinks = {
  __typename?: 'SocialLinks';
  instagram?: Maybe<Scalars['String']['output']>;
  twitter?: Maybe<Scalars['String']['output']>;
  url?: Maybe<Scalars['URL']['output']>;
  website?: Maybe<Scalars['String']['output']>;
};

export type SocialLinksInput = {
  instagram?: InputMaybe<Scalars['instagram_String_maxLength_30']['input']>;
  twitter?: InputMaybe<Scalars['twitter_String_maxLength_15']['input']>;
  website?: InputMaybe<Scalars['website_String_maxLength_512']['input']>;
};

export enum SocialPlatforms {
  Instagram = 'instagram',
  Twitter = 'twitter',
  Website = 'website'
}

export type Sponsor = {
  __typename?: 'Sponsor';
  link: Scalars['URL']['output'];
  logo: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type Sponsorship = {
  __typename?: 'Sponsorship';
  amount: Scalars['Float']['output'];
  benefit: CommunityBenefit;
  benefitId: Scalars['ID']['output'];
  duration: Scalars['Int']['output'];
  endDate: Scalars['Int']['output'];
  executionArn?: Maybe<Scalars['String']['output']>;
  perk: SponsorshipPerk;
  revenueShare: SponsorshipRevenueShare;
  sponsor: Profile;
  sponsorId: Scalars['ID']['output'];
  status: SponsorshipStatus;
  transactionHash?: Maybe<Scalars['String']['output']>;
};

export type SponsorshipClaimAction = {
  __typename?: 'SponsorshipClaimAction';
  claimText: Scalars['String']['output'];
  ctaLabel: Scalars['String']['output'];
  type: SponsorshipClaimActionType;
  url: Scalars['String']['output'];
};

export type SponsorshipClaimActionInput = {
  claimText: Scalars['String']['input'];
  ctaLabel: Scalars['String']['input'];
  type: SponsorshipClaimActionType;
  url: Scalars['String']['input'];
};

export enum SponsorshipClaimActionType {
  FollowOnX = 'followOnX',
  Link = 'link'
}

export type SponsorshipEarnings = {
  __typename?: 'SponsorshipEarnings';
  accountId: Scalars['ID']['output'];
  amount: Scalars['Float']['output'];
  benefit: Benefit;
  benefitId: Scalars['ID']['output'];
  chainId: Scalars['Int']['output'];
  collection: Collection;
};

export type SponsorshipInput = {
  link: Scalars['URL']['input'];
  logo: Scalars['String']['input'];
  name: Scalars['String']['input'];
};

export type SponsorshipPerk = {
  __typename?: 'SponsorshipPerk';
  claimActions: Array<SponsorshipClaimAction>;
  description: Scalars['String']['output'];
  thumbnailName: Scalars['String']['output'];
  thumbnailSize: Scalars['Int']['output'];
  thumbnailType: Scalars['String']['output'];
  thumbnailUrl: Scalars['String']['output'];
  title: Scalars['String']['output'];
};

export type SponsorshipPerkInput = {
  claimActions: Array<SponsorshipClaimActionInput>;
  description: Scalars['String']['input'];
  thumbnailName: Scalars['String']['input'];
  thumbnailSize: Scalars['Int']['input'];
  thumbnailType: Scalars['String']['input'];
  thumbnailUrl: Scalars['String']['input'];
  title: Scalars['String']['input'];
};

export type SponsorshipRevenueShare = {
  __typename?: 'SponsorshipRevenueShare';
  artist: Scalars['Int']['output'];
  community: Scalars['Int']['output'];
  remx: Scalars['Int']['output'];
};

export type SponsorshipRevenueShareInput = {
  artist: Scalars['Int']['input'];
  community: Scalars['Int']['input'];
  remx: Scalars['Int']['input'];
};

export enum SponsorshipStatus {
  Active = 'active',
  Complete = 'complete',
  DistributionComplete = 'distributionComplete',
  DistributionFailed = 'distributionFailed',
  FinalEmailSent = 'finalEmailSent',
  Funded = 'funded',
  Pending = 'pending',
  ReminderEmailSent = 'reminderEmailSent'
}

export type SponsorshipTransaction = {
  __typename?: 'SponsorshipTransaction';
  amount: Scalars['Float']['output'];
  contractAddress: Scalars['String']['output'];
  maxClaims: Scalars['Int']['output'];
  payees: Array<Scalars['String']['output']>;
  shares: Array<Scalars['Int']['output']>;
};

export type Subscription = {
  __typename?: 'Subscription';
  accountActivity?: Maybe<AccountActivity>;
  accountCreated?: Maybe<Account>;
  accountRemoved?: Maybe<Scalars['Boolean']['output']>;
  accountUpdated?: Maybe<Account>;
  auctionCreated?: Maybe<Auction>;
  auctionRemoved?: Maybe<Scalars['Boolean']['output']>;
  auctionUpdated?: Maybe<Auction>;
  benefitCreated?: Maybe<Benefit>;
  benefitRemoved?: Maybe<Scalars['Boolean']['output']>;
  benefitUpdated?: Maybe<Benefit>;
  collectionBenefitCreated?: Maybe<CollectionBenefit>;
  collectionBenefitRemoved?: Maybe<Scalars['Boolean']['output']>;
  collectionBenefitUpdated?: Maybe<CollectionBenefit>;
  collectionCreated?: Maybe<Collection>;
  collectionRemoved?: Maybe<Scalars['Boolean']['output']>;
  collectionUpdated?: Maybe<Collection>;
  commentCreated: Comment;
  commentRemoved: Comment;
  commentUpdated: Comment;
  communityUpdated?: Maybe<Community>;
  customWearableCreated?: Maybe<CustomWearable>;
  customWearableRemoved?: Maybe<Scalars['Boolean']['output']>;
  customWearableUpdated?: Maybe<CustomWearable>;
  modelCreated?: Maybe<Model>;
  modelRemoved?: Maybe<Scalars['Boolean']['output']>;
  modelUpdated?: Maybe<Model>;
  pendingMomentUpdated?: Maybe<PendingMoment>;
  postCreated: Post;
  postRemoved: Scalars['Boolean']['output'];
  postUpdated: Post;
  profileUpdated: Profile;
  reactionToggled?: Maybe<Scalars['Boolean']['output']>;
  settingCreated?: Maybe<Setting>;
  settingRemoved?: Maybe<Scalars['Boolean']['output']>;
  settingUpdated?: Maybe<Setting>;
  showroomCreated?: Maybe<Showroom>;
  showroomRemoved?: Maybe<Scalars['Boolean']['output']>;
  showroomUpdated?: Maybe<Showroom>;
  sponsorshipCreated: Sponsorship;
  sponsorshipUpdated: Sponsorship;
  tokenBenefitCreated?: Maybe<TokenBenefit>;
  tokenBenefitRemoved?: Maybe<Scalars['Boolean']['output']>;
  tokenBenefitUpdated?: Maybe<TokenBenefit>;
  tokenCreated?: Maybe<Token>;
  tokenRemoved?: Maybe<Scalars['Boolean']['output']>;
  tokenUpdated?: Maybe<Token>;
};


export type SubscriptionAccountActivityArgs = {
  id: Scalars['ID']['input'];
};


export type SubscriptionAccountRemovedArgs = {
  id: Scalars['ID']['input'];
};


export type SubscriptionAccountUpdatedArgs = {
  id: Scalars['ID']['input'];
};


export type SubscriptionAuctionRemovedArgs = {
  collectionId: Scalars['ID']['input'];
};


export type SubscriptionAuctionUpdatedArgs = {
  collectionId: Scalars['ID']['input'];
};


export type SubscriptionBenefitRemovedArgs = {
  id: Scalars['ID']['input'];
};


export type SubscriptionBenefitUpdatedArgs = {
  id: Scalars['ID']['input'];
};


export type SubscriptionCollectionBenefitRemovedArgs = {
  id: Scalars['ID']['input'];
};


export type SubscriptionCollectionBenefitUpdatedArgs = {
  id: Scalars['ID']['input'];
};


export type SubscriptionCollectionRemovedArgs = {
  id: Scalars['ID']['input'];
};


export type SubscriptionCollectionUpdatedArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
  status?: InputMaybe<CollectionStatus>;
  type?: InputMaybe<CollectionTypes>;
};


export type SubscriptionCommentCreatedArgs = {
  relationId: Scalars['ID']['input'];
};


export type SubscriptionCommentRemovedArgs = {
  id: Scalars['ID']['input'];
};


export type SubscriptionCommentUpdatedArgs = {
  id: Scalars['ID']['input'];
};


export type SubscriptionCommunityUpdatedArgs = {
  id: Scalars['ID']['input'];
};


export type SubscriptionCustomWearableRemovedArgs = {
  id: Scalars['ID']['input'];
};


export type SubscriptionCustomWearableUpdatedArgs = {
  id: Scalars['ID']['input'];
};


export type SubscriptionModelRemovedArgs = {
  id: Scalars['ID']['input'];
};


export type SubscriptionModelUpdatedArgs = {
  id: Scalars['ID']['input'];
};


export type SubscriptionPendingMomentUpdatedArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};


export type SubscriptionPostCreatedArgs = {
  communityId: Scalars['ID']['input'];
};


export type SubscriptionPostRemovedArgs = {
  id: Scalars['ID']['input'];
};


export type SubscriptionPostUpdatedArgs = {
  id: Scalars['ID']['input'];
};


export type SubscriptionProfileUpdatedArgs = {
  id: Scalars['ID']['input'];
};


export type SubscriptionReactionToggledArgs = {
  id: Scalars['ID']['input'];
};


export type SubscriptionSettingRemovedArgs = {
  id: Scalars['ID']['input'];
};


export type SubscriptionSettingUpdatedArgs = {
  id: Scalars['ID']['input'];
};


export type SubscriptionShowroomRemovedArgs = {
  id: Scalars['ID']['input'];
};


export type SubscriptionShowroomUpdatedArgs = {
  id: Scalars['ID']['input'];
};


export type SubscriptionTokenBenefitRemovedArgs = {
  id: Scalars['ID']['input'];
};


export type SubscriptionTokenBenefitUpdatedArgs = {
  id: Scalars['ID']['input'];
};


export type SubscriptionTokenRemovedArgs = {
  collectionId: Scalars['ID']['input'];
  id: Scalars['Int']['input'];
};


export type SubscriptionTokenUpdatedArgs = {
  collectionId: Scalars['ID']['input'];
  id: Scalars['Int']['input'];
};

export enum SubscriptionStatus {
  Active = 'active',
  Inactive = 'inactive'
}

export type SummaryData = {
  __typename?: 'SummaryData';
  dataset?: Maybe<DataSet>;
  total: Scalars['Int']['output'];
};

export type SummaryStatisticsResult = {
  __typename?: 'SummaryStatisticsResult';
  collected?: Maybe<SummaryData>;
  collectors?: Maybe<SummaryData>;
  engagements?: Maybe<SummaryData>;
  followers?: Maybe<SummaryData>;
};

export type SupportedNetworks = {
  __typename?: 'SupportedNetworks';
  chains: Array<Maybe<Chain>>;
  defaultChainId: Scalars['Int']['output'];
};

export type Tip = {
  __typename?: 'Tip';
  amount: Scalars['Float']['output'];
  benefit: Benefit;
  benefitId: Scalars['ID']['output'];
  currency: Scalars['String']['output'];
  date: Scalars['Int']['output'];
  profile: Profile;
  profileId: Scalars['ID']['output'];
  thanked: Scalars['Boolean']['output'];
  tokenId: Scalars['Int']['output'];
  value: Scalars['Float']['output'];
};

export type TipArtistInput = {
  amount: Scalars['Float']['input'];
  chainId: Scalars['Int']['input'];
  fundingSource: FundingSource;
  payerId: Scalars['ID']['input'];
  recipientId: Scalars['ID']['input'];
  value: Scalars['Float']['input'];
};

export type TipThankYouInput = {
  collectionId: Scalars['ID']['input'];
  tokenId: Scalars['Int']['input'];
};

export type ToggleReactionInput = {
  reactionType?: InputMaybe<ReactionType>;
  relationId: Scalars['ID']['input'];
  relationType: RelationType;
};

export type Token = {
  __typename?: 'Token';
  accountId: Scalars['ID']['output'];
  address: Scalars['ID']['output'];
  asset?: Maybe<Scalars['URL']['output']>;
  benefits: Array<TokenBenefit>;
  collection?: Maybe<Collection>;
  collectionId: Scalars['ID']['output'];
  description?: Maybe<Scalars['String']['output']>;
  design: Scalars['String']['output'];
  details?: Maybe<TokenDetails>;
  id: Scalars['Int']['output'];
  model?: Maybe<Model>;
  modelId: Scalars['ID']['output'];
  network?: Maybe<Scalars['String']['output']>;
  price?: Maybe<Scalars['Float']['output']>;
  rendered?: Maybe<Scalars['Boolean']['output']>;
  reservationTimeout?: Maybe<Scalars['Int']['output']>;
  status: TokenStatus;
  transactionHash?: Maybe<Scalars['String']['output']>;
};

export type TokenBenefit = {
  __typename?: 'TokenBenefit';
  benefit: Benefit;
  benefitId: Scalars['ID']['output'];
  collectionId: Scalars['ID']['output'];
  created: Scalars['Int']['output'];
  id: Scalars['ID']['output'];
  status: TokenBenefitStatusType;
  tokenId: Scalars['Int']['output'];
  updated: Scalars['Int']['output'];
};

export enum TokenBenefitStatusType {
  Claimed = 'claimed',
  Unclaimed = 'unclaimed'
}

export type TokenDetails = {
  __typename?: 'TokenDetails';
  animation_url: Scalars['String']['output'];
  description: Scalars['String']['output'];
  external_url: Scalars['String']['output'];
  image: Scalars['String']['output'];
  name: Scalars['String']['output'];
  properties: Scalars['JSONObject']['output'];
};

export type TokenDetailsResult = {
  __typename?: 'TokenDetailsResult';
  animation_url: Scalars['String']['output'];
  description: Scalars['String']['output'];
  external_url: Scalars['String']['output'];
  image: Scalars['String']['output'];
  name: Scalars['String']['output'];
  properties: Scalars['JSONObject']['output'];
};

export type TokenGate = {
  __typename?: 'TokenGate';
  address: Scalars['String']['output'];
  appliesTo: Array<CollectionStatus>;
  chainId?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type TokenGateInput = {
  address: Scalars['String']['input'];
  appliesTo: Array<CollectionStatus>;
  chainId: Scalars['Int']['input'];
};

export enum TokenLimit {
  Fixed = 'fixed',
  Single = 'single',
  Unlimited = 'unlimited'
}

export type TokenModel = {
  __typename?: 'TokenModel';
  collection: Collection;
  collectionId: Scalars['ID']['output'];
  model?: Maybe<Model>;
  modelId: Scalars['ID']['output'];
  token: Token;
  tokenId: Scalars['Int']['output'];
};

export type TokenPurchaseConfirming = {
  __typename?: 'TokenPurchaseConfirming';
  address: Scalars['ID']['output'];
  collectionId: Scalars['ID']['output'];
  collectionName: Scalars['String']['output'];
  collectionType: CollectionTypes;
  communitySlug: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  tokenId: Scalars['Int']['output'];
  transactionHash: Scalars['String']['output'];
};

export type TokenPurchaseFailed = {
  __typename?: 'TokenPurchaseFailed';
  address: Scalars['ID']['output'];
  collectionId: Scalars['ID']['output'];
  collectionName: Scalars['String']['output'];
  collectionType: CollectionTypes;
  communitySlug: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  tokenId: Scalars['Int']['output'];
  transactionHash: Scalars['String']['output'];
};

export type TokenPurchasePending = {
  __typename?: 'TokenPurchasePending';
  address: Scalars['ID']['output'];
  collectionId: Scalars['ID']['output'];
  collectionName: Scalars['String']['output'];
  collectionType: CollectionTypes;
  communitySlug: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  tokenId: Scalars['Int']['output'];
};

export type TokenPurchaseSuccess = {
  __typename?: 'TokenPurchaseSuccess';
  address: Scalars['ID']['output'];
  collectionId: Scalars['ID']['output'];
  collectionName: Scalars['String']['output'];
  collectionType: CollectionTypes;
  communitySlug: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  tokenId: Scalars['Int']['output'];
  transactionHash: Scalars['String']['output'];
};

export type TokenRenderComplete = {
  __typename?: 'TokenRenderComplete';
  address: Scalars['ID']['output'];
  collectionId: Scalars['ID']['output'];
  collectionName: Scalars['String']['output'];
  collectionType: CollectionTypes;
  communitySlug: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  tokenId: Scalars['Int']['output'];
  transactionHash: Scalars['String']['output'];
};

export enum TokenStatus {
  Confirming = 'confirming',
  Missing = 'missing',
  Pending = 'pending',
  Released = 'released',
  Sold = 'sold'
}

export type TokenSupply = {
  __typename?: 'TokenSupply';
  owned: Scalars['Int']['output'];
  remaining: Scalars['Int']['output'];
  used: Scalars['Int']['output'];
};

export type TokenTransfer = {
  __typename?: 'TokenTransfer';
  address: Scalars['ID']['output'];
  collectionId: Scalars['ID']['output'];
  collectionName: Scalars['String']['output'];
  fromAccount: Scalars['ID']['output'];
  id: Scalars['ID']['output'];
  toAccount: Scalars['ID']['output'];
  tokenId: Scalars['Int']['output'];
  transactionHash: Scalars['String']['output'];
};

export enum TokenType {
  ThreeDimensional = 'threeDimensional',
  TwoDimensional = 'twoDimensional'
}

export type TopCollector = {
  __typename?: 'TopCollector';
  claims: Scalars['Int']['output'];
  icon?: Maybe<Scalars['String']['output']>;
  isFollowing?: Maybe<Scalars['Boolean']['output']>;
  slug: Scalars['String']['output'];
  tips: Scalars['Int']['output'];
  username: Scalars['String']['output'];
};

export type TopTipGiven = {
  __typename?: 'TopTipGiven';
  icon?: Maybe<Scalars['String']['output']>;
  slug: Scalars['String']['output'];
  tips: Scalars['Int']['output'];
  username: Scalars['String']['output'];
};

export type TopTipReceived = {
  __typename?: 'TopTipReceived';
  icon?: Maybe<Scalars['String']['output']>;
  slug: Scalars['String']['output'];
  tips: Scalars['Int']['output'];
  username: Scalars['String']['output'];
};

export type TopTippedMoment = {
  __typename?: 'TopTippedMoment';
  accountId: Scalars['String']['output'];
  benefit: Benefit;
  benefitId: Scalars['String']['output'];
  profile: Profile;
  tips: Scalars['Int']['output'];
};

export type TopTipper = {
  __typename?: 'TopTipper';
  icon?: Maybe<Scalars['String']['output']>;
  isFollowing?: Maybe<Scalars['Boolean']['output']>;
  slug: Scalars['String']['output'];
  tips: Scalars['Int']['output'];
  username: Scalars['String']['output'];
};

export type TopTippingStreak = {
  __typename?: 'TopTippingStreak';
  icon?: Maybe<Scalars['String']['output']>;
  slug: Scalars['String']['output'];
  tips: Scalars['Int']['output'];
  username: Scalars['String']['output'];
};

export type TransactionResult = {
  __typename?: 'TransactionResult';
  amount: Scalars['Float']['output'];
  date: Scalars['Int']['output'];
  event: Scalars['String']['output'];
  success: Scalars['Boolean']['output'];
  transactionId: Scalars['String']['output'];
  txHash: Scalars['String']['output'];
};

export type TransferTokenInput = {
  collectionId: Scalars['ID']['input'];
  toAddress: Scalars['String']['input'];
  tokenId: Scalars['Int']['input'];
};

export type TransferTokenResult = {
  __typename?: 'TransferTokenResult';
  success: Scalars['Boolean']['output'];
};

export type TrendingArtist = {
  __typename?: 'TrendingArtist';
  icon?: Maybe<Scalars['String']['output']>;
  isFollowing?: Maybe<Scalars['Boolean']['output']>;
  slug: Scalars['String']['output'];
  tips: Scalars['Int']['output'];
  username: Scalars['String']['output'];
};

export type TwitterAccessToken = {
  __typename?: 'TwitterAccessToken';
  screen_name: Scalars['String']['output'];
  user_id: Scalars['String']['output'];
};

export type TwitterAccessTokenInput = {
  oauthToken: Scalars['String']['input'];
  oauthVerifier: Scalars['String']['input'];
};

export type TwitterAuthResult = {
  __typename?: 'TwitterAuthResult';
  oauth_token: Scalars['String']['output'];
};

export type TwitterRequestTokenInput = {
  url?: InputMaybe<Scalars['String']['input']>;
};

export type TypedDataDefinition = {
  __typename?: 'TypedDataDefinition';
  ForwardRequest: Array<TypedDataField>;
};

export type TypedDataField = {
  __typename?: 'TypedDataField';
  name: Scalars['String']['output'];
  type: Scalars['String']['output'];
};

export type UnarchiveCollectionInput = {
  id: Scalars['ID']['input'];
};

export type UnpublishCollectionInput = {
  id: Scalars['ID']['input'];
};

export type UpdateAddressInput = {
  accountId: Scalars['ID']['input'];
  address: Scalars['ID']['input'];
  connectionType?: InputMaybe<Scalars['String']['input']>;
  label?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateAuctionInput = {
  airdropSize: Scalars['Int']['input'];
  collectionId: Scalars['ID']['input'];
  comingSoonDate: Scalars['String']['input'];
  countdownDate: Scalars['String']['input'];
  currency?: InputMaybe<Currency>;
  endDate?: InputMaybe<Scalars['String']['input']>;
  floorPrice: Scalars['Float']['input'];
  launchType: LaunchDateType;
  presaleDate?: InputMaybe<Scalars['String']['input']>;
  privatesaleDate?: InputMaybe<Scalars['String']['input']>;
  privatesaleSize: Scalars['Int']['input'];
  saleDate: Scalars['String']['input'];
};

export type UpdateBenefitInput = {
  categories?: InputMaybe<Array<BenefitCategory>>;
  description: Scalars['String']['input'];
  endDate?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  listed?: InputMaybe<Scalars['Boolean']['input']>;
  metadata?: InputMaybe<Scalars['JSON']['input']>;
  roles: Array<BenefitRoleType>;
  scope: BenefitScopeType;
  sponsor?: InputMaybe<SponsorshipInput>;
  startDate?: InputMaybe<Scalars['String']['input']>;
  title: Scalars['String']['input'];
  type: BenefitType;
  usage: BenefitUsageType;
};

export type UpdateCollectionBenefitInput = {
  benefitId: Scalars['ID']['input'];
  collectionId: Scalars['ID']['input'];
  sortOrder?: InputMaybe<Scalars['Int']['input']>;
};

export type UpdateCollectionInput = {
  auction?: InputMaybe<CreateAuctionInput>;
  banner?: InputMaybe<Scalars['URL']['input']>;
  chainId?: InputMaybe<Scalars['Int']['input']>;
  communityIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  contractId?: InputMaybe<Scalars['String']['input']>;
  contractName?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  icon?: InputMaybe<Scalars['URL']['input']>;
  id: Scalars['ID']['input'];
  metadata?: InputMaybe<Scalars['JSON']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  params?: InputMaybe<Scalars['String']['input']>;
  premints?: InputMaybe<Array<PremintListInput>>;
  size?: InputMaybe<Scalars['Int']['input']>;
  status?: InputMaybe<CollectionStatus>;
  tokenGates?: InputMaybe<Array<TokenGateInput>>;
  tokenLimit?: InputMaybe<TokenLimit>;
};

export type UpdateCommentInput = {
  content?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

export type UpdateCommunityInput = {
  accountId: Scalars['ID']['input'];
  banner?: InputMaybe<Scalars['URL']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  membershipCard?: InputMaybe<Scalars['URL']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  planId?: InputMaybe<Scalars['ID']['input']>;
  published?: InputMaybe<Scalars['Boolean']['input']>;
  settings?: InputMaybe<CommunitySettingsInput>;
  slug?: InputMaybe<Scalars['String']['input']>;
  social?: InputMaybe<SocialLinksInput>;
};

export type UpdateCommunityMemberInput = {
  accountId: Scalars['ID']['input'];
  communityId: Scalars['ID']['input'];
  notifications?: InputMaybe<CommunityMemberNotificationsInput>;
  role?: InputMaybe<CommunityMemberRole>;
};

export type UpdateCustomWearableInput = {
  accountId?: InputMaybe<Scalars['ID']['input']>;
  color?: InputMaybe<Scalars['String']['input']>;
  icon?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  modelId?: InputMaybe<Scalars['ID']['input']>;
  texture?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateGenesisBenefitInput = {
  benefitId: Scalars['ID']['input'];
  status: TokenBenefitStatusType;
};

export type UpdateModelInput = {
  elements: Scalars['JSONObject']['input'];
  file: Scalars['String']['input'];
  icon: Scalars['String']['input'];
  id: Scalars['ID']['input'];
  lights: Scalars['String']['input'];
  listed: Scalars['Boolean']['input'];
  metals: Scalars['String']['input'];
  name: Scalars['String']['input'];
  orthoOffset: Scalars['Float']['input'];
};

export type UpdatePendingMomentInput = {
  benefitMetadata?: InputMaybe<Scalars['JSON']['input']>;
  categories?: InputMaybe<Array<BenefitCategory>>;
  chainId?: InputMaybe<Scalars['Int']['input']>;
  collectionMetadata?: InputMaybe<Scalars['JSON']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  dropType?: InputMaybe<DropType>;
  icon?: InputMaybe<Scalars['URL']['input']>;
  id: Scalars['ID']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  price?: InputMaybe<Scalars['Float']['input']>;
};

export type UpdatePostInput = {
  content?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  locked?: InputMaybe<Scalars['Boolean']['input']>;
  media?: InputMaybe<Array<MediaInput>>;
};

export type UpdateProfileInput = {
  banner?: InputMaybe<Scalars['URL']['input']>;
  bio?: InputMaybe<Scalars['bio_String_maxLength_256']['input']>;
  defaultWallet?: InputMaybe<Scalars['String']['input']>;
  displayName?: InputMaybe<Scalars['String']['input']>;
  farcasterAddress?: InputMaybe<Scalars['String']['input']>;
  farcasterUsername?: InputMaybe<Scalars['String']['input']>;
  fid?: InputMaybe<Scalars['String']['input']>;
  icon?: InputMaybe<Scalars['URL']['input']>;
  id: Scalars['ID']['input'];
  notifications?: InputMaybe<AccountNotificationsInput>;
  twitterId?: InputMaybe<Scalars['String']['input']>;
  twitterUsername?: InputMaybe<Scalars['String']['input']>;
  username?: InputMaybe<Scalars['username_String_minLength_3']['input']>;
  verifiedType?: InputMaybe<VerifiedType>;
  website?: InputMaybe<Scalars['URL']['input']>;
};

export type UpdateRewardInput = {
  benefitId: Scalars['ID']['input'];
  benefitMetadata?: InputMaybe<Scalars['JSON']['input']>;
  categories?: InputMaybe<Array<BenefitCategory>>;
  chainId?: InputMaybe<Scalars['Int']['input']>;
  collaborators?: InputMaybe<Array<CollaboratorInput>>;
  collectionMetadata?: InputMaybe<Scalars['JSON']['input']>;
  communityId: Scalars['ID']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  icon?: InputMaybe<Scalars['URL']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  price?: InputMaybe<Scalars['Float']['input']>;
  size?: InputMaybe<Scalars['Int']['input']>;
  sponsor?: InputMaybe<SponsorshipInput>;
  tokenLimit?: InputMaybe<TokenLimit>;
};

export type UpdateShowroomInput = {
  accountId?: InputMaybe<Scalars['ID']['input']>;
  avatar?: InputMaybe<ShowroomModelInput>;
  garments?: InputMaybe<Array<ShowroomModelInput>>;
  id: Scalars['ID']['input'];
  pose?: InputMaybe<PoseType>;
  room?: InputMaybe<ShowroomModelInput>;
};

export type UpdateSponsorshipInput = {
  amount?: InputMaybe<Scalars['Float']['input']>;
  benefitId?: InputMaybe<Scalars['ID']['input']>;
  duration?: InputMaybe<Scalars['Int']['input']>;
  endDate?: InputMaybe<Scalars['Int']['input']>;
  perk?: InputMaybe<SponsorshipPerkInput>;
  revenueShare?: InputMaybe<SponsorshipRevenueShareInput>;
  sponsorId?: InputMaybe<Scalars['ID']['input']>;
};

export enum VerifiedType {
  Ai = 'ai',
  Human = 'human',
  Unverified = 'unverified'
}

export type VerifyEmailAddressInput = {
  email: Scalars['String']['input'];
  nonce: Scalars['String']['input'];
};

export type VerifyWalletInput = {
  address: Scalars['String']['input'];
  connectionType: Scalars['String']['input'];
  label: Scalars['String']['input'];
  signature: Scalars['String']['input'];
  type: Scalars['String']['input'];
};

export type Wallet = {
  __typename?: 'Wallet';
  address: Scalars['String']['output'];
  connectionType: Scalars['String']['output'];
  label: Scalars['String']['output'];
  type: Scalars['String']['output'];
};

export type WalletChallenge = {
  __typename?: 'WalletChallenge';
  message: Scalars['String']['output'];
};

export enum WalletType {
  CrossMint = 'crossMint',
  Injected = 'injected',
  WalletConnect = 'walletConnect'
}

export type WithdrawEarningsInput = {
  address: Scalars['ID']['input'];
  chainId: Scalars['Int']['input'];
  signature: Scalars['String']['input'];
};

export type WithdrawalResult = {
  __typename?: 'WithdrawalResult';
  amount: Scalars['Float']['output'];
  date: Scalars['Int']['output'];
  transactionId: Scalars['String']['output'];
};

export type ToggleListedCollectionInput = {
  id: Scalars['ID']['input'];
};
